import { useContext, useEffect, useState } from 'react';
import { globalContext } from 'src/context';

import * as S from './style';

export const MainContainer: React.FC = ({ children }) => {
  const { state } = useContext(globalContext);

  const [expanded, setExpanded] = useState(state.sidebarExpanded);

  useEffect(() => {
    setExpanded(state.sidebarExpanded);
  }, [state.sidebarExpanded]);

  return (
    <>
      <S.MainContainer expanded={expanded} dark={state.darkBg}>
        {children}
      </S.MainContainer>
    </>
  );
};
