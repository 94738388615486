import { motion } from 'framer-motion';
import { rgba } from 'polished';
import { flexRowCentered } from 'src/components/utils/helpers';
import styled from 'styled-components';

export const IconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
`;

export const NewIconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
`;

export const NextStepsCounterText = styled.div<{ large: boolean }>`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  text-align: center;
  line-height: 17px;
  letter-spacing: -0.5px;
  font-weight: 600;
  font-size: ${(props) => (props.large ? 10 : 10)}px;
  color: ${(props) => props.theme.colors.alert.dark};
`;

export const NextStepsRouteCounterText = styled.div`
  background-color: ${(props) => props.theme.colors.alert.light2};
  color: ${(props) => props.theme.colors.alert.accentRed};
  padding: 0px 12px;
  margin-left: 8px;
  border-radius: 16px;
  font-size: 9px;
  line-height: 16px;
`;

export const RouteContainer = styled.div<{ active: boolean; disabled?: boolean; collapsed?: boolean }>`
  margin: 0px 8px;
  padding: 0px 16px;
  padding-right: 8px;
  width: 168px;
  opacity: ${(props) => (props.collapsed ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 11px;
  border-radius: 8px;
  margin-bottom: 8px;
  line-height: 1.2;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.grayscale.line
      : props.active
      ? props.theme.colors.primary.blue
      : props.theme.colors.primary.darkBlue2};
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  text-align: start;
  background-color: ${(props) => (props.active ? rgba(props.theme.colors.primary.blue, 0.1) : '')};
  font-weight: 500;

  &:hover {
    /* background-color: ${(props) => rgba(props.theme.colors.primary.blue, 0.1)}; */
    color: ${(props) =>
      props.disabled
        ? ''
        : props.active
        ? props.theme.colors.primary.blue
        : props.theme.colors.grayscale.gray1};
  }
`;

export const CompetitorIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
`;

export const DefaultIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.grayscale.line};
`;

export const ConnectionContainer = styled.div<{ importing?: boolean }>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  margin: 0 8px;
  flex-direction: column;
  margin-bottom: 24px;
  flex-direction: column;
  color: ${(props) =>
    props.importing ? props.theme.colors.grayscale.titleDark : props.theme.colors.primary.darkBlue2};
  border: 1px solid
    ${(props) => (props.importing ? props.theme.colors.primary.blue : props.theme.colors.alert.dark)};
  background-color: ${(props) =>
    props.importing ? props.theme.colors.primary.lightBlue3 : props.theme.colors.alert.light3};
  justify-content: center;
`;

export const ImportDescriptionText = styled.div`
  font-size: 9px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary.darkBlue1};
  padding-top: 8px;
`;

export const RouteDropdownContainer = styled(motion.div)`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.inputBg};
  background-color: ${(props) => props.theme.colors.grayscale.elementBg};
  padding: 8px;
  margin-bottom: 8px;
`;

export const DropdownRoute = styled.div<{ active: boolean; disabled?: boolean }>`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 11px;
  border-radius: 8px;
  margin-bottom: 8px;
  line-height: 1.2;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.grayscale.line
      : props.active
      ? props.theme.colors.primary.blue
      : props.theme.colors.primary.darkBlue2};
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  text-align: start;
  background-color: ${(props) => (props.active ? rgba(props.theme.colors.primary.blue, 0.1) : '')};
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    /* background-color: ${(props) => rgba(props.theme.colors.primary.blue, 0.1)}; */
    color: ${(props) =>
      props.disabled
        ? ''
        : props.active
        ? props.theme.colors.primary.blue
        : props.theme.colors.grayscale.gray1};
  }
`;

export const ImportStatusBar = styled.div`
  border-radius: 6px;
  height: 10px;
  background-color: white;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  position: relative;
`;

export const ImportProgressBar = styled.div<{ width: number }>`
  border-radius: 6px;
  height: 10px;
  background-color: ${(props) => props.theme.colors.primary.blue};
  width: ${(props) => `${props.width}%`};
  position: absolute;
  left: 0;
  top: 0;
`;

export const ImportStatusValue = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary.blue};
`;

export const DescriptionText = styled.div`
  font-size: 9px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary.darkBlue1};
  padding-bottom: 8px;
`;

export const RefreshConnectionContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  margin: 0 8px;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.alert.light2};
  color: ${(props) => props.theme.colors.grayscale.body};
`;

export const LockedModalWrapper = styled.div`
  padding: 128px 64px;
`;

export const SectionIconContainer = styled(motion.div)<{
  active?: boolean;
  disconnected?: boolean;
  locked?: boolean;
}>`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.locked
        ? props.active
          ? props.theme.colors.notifications.default
          : rgba(props.theme.colors.notifications.default, 0.3)
        : props.disconnected
        ? props.active
          ? props.theme.colors.alert.accentRed
          : rgba(props.theme.colors.alert.accentRed, 0.3)
        : props.active
        ? props.theme.colors.primary.accentBlue
        : rgba(props.theme.colors.grayscale.gray1, 0.5)};
  background-color: ${(props) =>
    props.locked
      ? props.active
        ? rgba(props.theme.colors.notifications.default, 0.2)
        : 'none'
      : props.disconnected
      ? `${rgba(props.theme.colors.alert.accentRed, 0.2)}`
      : props.active
      ? `${rgba(props.theme.colors.primary.blue, 0.15)}`
      : 'inherit'};

  margin-bottom: 16px;
  cursor: pointer;
  ${flexRowCentered};
  position: relative;

  &:hover {
    background-color: ${(props) =>
      props.locked
        ? rgba(props.theme.colors.notifications.border, 0.3)
        : props.disconnected
        ? `${rgba(props.theme.colors.alert.accentRed, 0.3)}`
        : `${rgba(props.theme.colors.primary.blue, 0.15)}`};
  }
`;
