import type { ReactElement } from 'react';
import { SplashLayout } from 'src/components/Layout/SplashLayout';

import { PageContainer } from 'src/components/utils/LayoutContainers';
import { NextPageWithLayout } from './_app';

const Custom404: NextPageWithLayout = () => {
  return <PageContainer>404 - Page Not Found</PageContainer>;
};

Custom404.getLayout = function getLayout(page: ReactElement) {
  return <SplashLayout>{page}</SplashLayout>;
};

export default Custom404;
