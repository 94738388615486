import React from 'react';

export const FacebookIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 5.37309 18.6269 0 12 0C5.37309 0 0 5.37309 0 12C0 17.989 4.38761 22.9537 10.1252 23.8549V15.4696H7.07748V12H10.1252V9.35562C10.1252 6.34842 11.9173 4.68621 14.6578 4.68621C15.9707 4.68621 17.3444 4.92077 17.3444 4.92077V7.87398H15.8306C14.3406 7.87398 13.8748 8.79875 13.8748 9.7488V11.9999H17.2026L16.671 15.4695H13.8747V23.8549C19.6123 22.9553 23.9999 17.9906 23.9999 11.9999L24 12Z"
        fill="#4267B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6711 15.4693L17.2027 11.9997H13.875V9.74863C13.875 8.80024 14.339 7.87381 15.8308 7.87381H17.3446V4.92059C17.3446 4.92059 15.971 4.68604 14.658 4.68604C11.9175 4.68604 10.1253 6.34659 10.1253 9.35545V11.9998H7.07764V15.4694H10.1253V23.8548C10.7362 23.9508 11.3623 23.9998 12.0002 23.9998C12.638 23.9998 13.2641 23.9492 13.875 23.8548V15.4694H16.6712L16.6711 15.4693Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
