import React from 'react';

export const GoogleSidebarIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.80154 3.19126C6.99485 2.68382 7.26066 2.21664 7.65534 1.83807C9.23407 0.29961 11.8438 0.686238 12.9151 2.61938C13.7206 4.08534 14.5744 5.51909 15.404 6.96894C16.7894 9.37731 18.191 11.7857 19.5603 14.2021C20.7121 16.2239 19.4636 18.7772 17.1761 19.1236C15.7745 19.333 14.4616 18.6886 13.7367 17.4321C12.5204 15.3137 11.2961 13.1953 10.0798 11.0849C10.0557 11.0366 10.0234 10.9963 9.99122 10.956C9.86234 10.8513 9.80596 10.6983 9.72541 10.5614C9.18575 9.6109 8.62997 8.66849 8.0903 7.72609C7.74395 7.11393 7.38148 6.50982 7.03513 5.89766C6.72099 5.34994 6.57601 4.75389 6.59212 4.12562C6.61628 3.80343 6.65655 3.48124 6.80154 3.19126Z"
        fill="#3C8BD9"
      />
      <path
        d="M6.81045 3.19128C6.73795 3.48126 6.67351 3.77123 6.65741 4.07731C6.63324 4.75391 6.80239 5.38218 7.14069 5.97017C8.02671 7.49252 8.91274 9.02292 9.7907 10.5533C9.87125 10.6903 9.93569 10.8272 10.0162 10.9561C9.53295 11.7938 9.04967 12.6234 8.55833 13.4611C7.88173 14.629 7.20513 15.805 6.52047 16.973C6.48826 16.973 6.4802 16.9569 6.47215 16.9327C6.46409 16.8682 6.48826 16.8119 6.50437 16.7474C6.83461 15.5392 6.56075 14.4679 5.73111 13.5497C5.22366 12.9939 4.57928 12.6798 3.83824 12.5751C2.87167 12.4381 2.01787 12.6878 1.25267 13.2919C1.11574 13.3967 1.02714 13.5497 0.866042 13.6302C0.833823 13.6302 0.817714 13.6141 0.809659 13.59C1.19629 12.9214 1.57486 12.2529 1.96149 11.5843C3.55633 8.8135 5.15117 6.04267 6.75406 3.27989C6.77017 3.24767 6.79434 3.2235 6.81045 3.19128Z"
        fill="#FABC04"
      />
      <path
        d="M0.837516 13.6141C0.990556 13.4772 1.13554 13.3322 1.29664 13.2033C3.25394 11.6568 6.19392 12.7764 6.62083 15.2251C6.72554 15.8131 6.66916 16.3769 6.49195 16.9407C6.4839 16.9891 6.47584 17.0293 6.45973 17.0777C6.38724 17.2065 6.3228 17.3435 6.24225 17.4723C5.52538 18.6564 4.47021 19.2444 3.08479 19.1558C1.49801 19.043 0.249519 17.8509 0.0320407 16.2722C-0.0726711 15.507 0.0803692 14.7901 0.475052 14.1296C0.555599 13.9846 0.652256 13.8558 0.740859 13.7108C0.781132 13.6785 0.765023 13.6141 0.837516 13.6141Z"
        fill="#34A852"
      />
      <path
        d="M0.836321 13.6144C0.803841 13.6469 0.803841 13.7037 0.747001 13.7118C0.738881 13.655 0.771361 13.6225 0.803841 13.5819L0.836321 13.6144Z"
        fill="#FABC04"
      />
      <path
        d="M6.45521 17.0788C6.42274 17.0219 6.45522 16.9813 6.4877 16.9407C6.49582 16.9488 6.51235 16.9648 6.52047 16.973L6.45521 17.0788Z"
        fill="#E1C025"
      />
    </svg>
  );
};
