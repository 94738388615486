import React from 'react';

export const HourglassIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16V16C3.58172 16 -1.02838e-06 12.4183 -1.41464e-06 8V8C-1.8009e-06 3.58172 3.58172 3.70385e-07 8 -1.58735e-08V-1.58735e-08C12.4183 -4.02132e-07 16 3.58172 16 8V8Z"
        fill="#BCE4FC"
      />
      <path
        d="M11.125 11.4375C11.125 11.6861 11.0262 11.9246 10.8504 12.1004C10.6746 12.2762 10.4361 12.375 10.1875 12.375L5.8125 12.375C5.56386 12.375 5.3254 12.2762 5.14959 12.1004C4.97377 11.9246 4.875 11.6861 4.875 11.4375C4.875 11.1889 4.97377 10.9504 5.14959 10.7746C5.3254 10.5988 5.56386 10.5 5.8125 10.5L10.1875 10.5C10.4361 10.5 10.6746 10.5988 10.8504 10.7746C11.0262 10.9504 11.125 11.1889 11.125 11.4375ZM4.875 4.5625C4.875 4.31386 4.97377 4.0754 5.14959 3.89959C5.3254 3.72377 5.56386 3.625 5.8125 3.625L10.1875 3.625C10.4084 3.62458 10.6223 3.70235 10.7913 3.84453C10.9604 3.98671 11.0736 4.18413 11.1111 4.40182C11.1485 4.61951 11.1077 4.84342 10.9958 5.0339C10.884 5.22437 10.7083 5.36912 10.5 5.4425L10.5 6.23812C10.5003 6.54413 10.4107 6.84347 10.2424 7.09902C10.074 7.35457 9.83437 7.55506 9.55312 7.67562L8.79312 8L9.55312 8.32563C9.83418 8.44611 10.0737 8.64641 10.242 8.90171C10.4103 9.15701 10.5 9.45609 10.5 9.76187L10.5 10.2306C10.3981 10.2029 10.2931 10.1884 10.1875 10.1875L5.8125 10.1875C5.70686 10.1888 5.60183 10.2037 5.5 10.2319L5.5 9.76313C5.49947 9.45703 5.58899 9.15753 5.75741 8.90193C5.92583 8.64633 6.16571 8.44591 6.44719 8.32563L7.20687 8L6.44719 7.67438C6.1659 7.55418 5.92616 7.35395 5.75775 7.09859C5.58935 6.84324 5.49972 6.54401 5.5 6.23813L5.5 5.4425C5.31784 5.3781 5.16003 5.25899 5.04815 5.10147C4.93628 4.94395 4.8758 4.75571 4.875 4.5625ZM9.875 6.23812L9.875 5.5L6.125 5.5L6.125 6.23813C6.12496 6.42162 6.17877 6.60109 6.27976 6.7543C6.38074 6.9075 6.52447 7.0277 6.69312 7.1L8 7.66L9.30687 7.1C9.47553 7.0277 9.61925 6.9075 9.72024 6.7543C9.82123 6.60109 9.87504 6.42162 9.875 6.23812Z"
        fill="#283366"
      />
      <path
        d="M9.87988 6.23828L9.87988 5.50016L6.12988 5.50016L6.12988 6.23828C6.12984 6.42178 6.18365 6.60125 6.28464 6.75445C6.38563 6.90766 6.52936 7.02786 6.69801 7.10016L8.00488 7.66016L9.31176 7.10016C9.48041 7.02786 9.62414 6.90766 9.72513 6.75445C9.82611 6.60125 9.87992 6.42178 9.87988 6.23828Z"
        fill="white"
      />
    </svg>
  );
};
