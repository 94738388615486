import React from 'react';

export const ShopifyIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.3932 4.71428C18.3766 4.59428 18.2715 4.52787 18.1846 4.52055C18.0979 4.51332 16.2628 4.37729 16.2628 4.37729C16.2628 4.37729 14.9882 3.11195 14.8483 2.9719C14.7083 2.83192 14.435 2.8745 14.3289 2.90573C14.3132 2.91033 14.0504 2.99146 13.6156 3.126C13.1898 1.90085 12.4385 0.774991 11.1166 0.774991C11.0801 0.774991 11.0425 0.776471 11.0049 0.778608C10.629 0.28143 10.1633 0.0654297 9.76105 0.0654297C6.68174 0.0654297 5.21059 3.91488 4.74933 5.87105C3.55278 6.24181 2.70276 6.5054 2.59418 6.53951C1.92629 6.74902 1.90517 6.77006 1.81747 7.3994C1.75147 7.87587 0.00390625 21.3906 0.00390625 21.3906L13.6213 23.942L20.9996 22.3458C20.9996 22.3458 18.4095 4.83428 18.3932 4.71428H18.3932ZM12.863 3.35877L11.7107 3.7154C11.7111 3.6342 11.7115 3.55431 11.7115 3.46694C11.7115 2.70551 11.6059 2.09244 11.4363 1.60644C12.1174 1.69192 12.571 2.46691 12.863 3.35877ZM10.5914 1.75743C10.7807 2.23192 10.9039 2.91288 10.9039 3.83179C10.9039 3.8788 10.9034 3.92179 10.903 3.96527C10.1537 4.19738 9.33941 4.44937 8.52333 4.7022C8.98155 2.93376 9.84045 2.07962 10.5914 1.75743V1.75743ZM9.67648 0.891375C9.80938 0.891375 9.94327 0.936498 10.0714 1.02469C9.08453 1.48907 8.02673 2.65866 7.58001 4.99431L5.69897 5.57688C6.22221 3.79537 7.46462 0.891375 9.6764 0.891375H9.67648Z"
        fill="#95BF46"
      />
      <path
        d="M18.1853 4.51936C18.0986 4.51212 16.2634 4.3761 16.2634 4.3761C16.2634 4.3761 14.9888 3.11075 14.8489 2.9707C14.7966 2.91859 14.726 2.89188 14.6522 2.88037L13.6226 23.9406L21.0003 22.3446C21.0003 22.3446 18.4101 4.83308 18.3938 4.71308C18.3773 4.59308 18.2721 4.52667 18.1853 4.51936"
        fill="#5E8E3E"
      />
      <path
        d="M11.1172 8.59581L10.2075 11.3021C10.2075 11.3021 9.41036 10.8766 8.43326 10.8766C7.00082 10.8766 6.92874 11.7756 6.92874 12.0021C6.92874 13.2381 10.1507 13.7117 10.1507 16.6068C10.1507 18.8846 8.70598 20.3513 6.75803 20.3513C4.4205 20.3513 3.2251 18.8965 3.2251 18.8965L3.85099 16.8285C3.85099 16.8285 5.07976 17.8835 6.11661 17.8835C6.79411 17.8835 7.0697 17.3501 7.0697 16.9603C7.0697 15.348 4.42641 15.2761 4.42641 12.6268C4.42641 10.397 6.02685 8.23926 9.25748 8.23926C10.5023 8.23926 11.1172 8.59581 11.1172 8.59581"
        fill="white"
      />
    </svg>
  );
};
