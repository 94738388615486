import { ReactElement, useEffect } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { IntercomProvider } from 'react-use-intercom';
import { hotjar } from 'react-hotjar';
import { ChakraProvider } from '@chakra-ui/react';

import { Layout } from 'src/components/Layout';
import { GlobalContextProvider } from 'src/context/provider';
import { Root } from 'src/modules/Root';
import { GlobalStyle } from 'styles/globalStyle';
import { darkTheme } from 'styles/themes';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'styles/masonry.css';
import { AnalyticsService } from 'src/services/Analytics';
import theme from 'styles/chakra/theme';
import { APPS_CONFIG } from 'src/appConfig';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => JSX.Element;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout: (page: ReactElement) => JSX.Element =
    Component.getLayout ??
    ((page) => (
      <Layout>
        <Root>{page}</Root>
      </Layout>
    ));

  const component = getLayout(<Component {...pageProps} />);

  useEffect(() => {
    // hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_HJID, process.env.NEXT_PUBLIC_HOTJAR_HJSV);
    AnalyticsService.init();
  }, []);

  return (
    <>
      <Head>
        <title>Lebesgue</title>
      </Head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-M1L6GGV17E"></script>
      <script defer type="text/javascript" src="/static/googletagmanager.js"></script>
      <GlobalStyle />

      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
      <script src="https://accounts.google.com/gsi/client" async defer />
      <GlobalContextProvider>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_ID || ''}
          autoBoot
          shouldInitialize={typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENV === 'prod'}
        >
          <ChakraProvider theme={theme}>
            <ThemeProvider theme={darkTheme}>{component}</ThemeProvider>
          </ChakraProvider>
        </IntercomProvider>
      </GlobalContextProvider>
    </>
  );
}

export default MyApp;
