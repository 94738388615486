import React from 'react';

export const AlertIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="28" cy="28" r="28" fill="#C30052" />
      <path
        d="M27.9985 16.3335V28.0001"
        stroke="#FCFCFC"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 37.3335V38.5002"
        stroke="#FCFCFC"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
