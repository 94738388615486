import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
`;

export const ModalContent = styled(motion.div)<{
  transparent?: boolean;
  scrollable: boolean;
  isLarge: boolean;
}>`
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background-color: ${(props) => (props.transparent ? 'transparent' : 'white')};
  pointer-events: all;
  max-height: ${(props) => (props.isLarge ? '90vh' : '85vh')};
  overflow: ${(props) => (props.scrollable ? 'scroll' : 'visible')};
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 1100;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DrawerModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
`;

export const DrawerModalContent = styled(motion.div)<{ transparent?: boolean }>`
  position: relative;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 416px;
  background-color: ${(props) => (props.transparent ? 'transparent' : 'white')};
  pointer-events: all;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 1100;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: ${(props) => props.theme.colors.grayscale.gray1};
  z-index: 100;
`;

export const CloseButtonContainer = styled(motion.div)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
