import React from 'react';

export const BusinessNavigatorIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M2.98657 14.9339L9.70656 6.46094L17.1732 14.9339L25.0132 2.9873" stroke="#00B0E8" />
      <circle cx="2.98666" cy="14.9339" r="2.98666" fill="#EBF5FF" />
      <circle cx="17.1732" cy="14.9339" r="2.98666" fill="#00B0E8" />
      <circle cx="25.0133" cy="2.98666" r="2.98666" fill="#F1B43C" />
      <circle cx="9.70663" cy="5.98666" r="2.98666" fill="#BCE4FC" />
    </svg>
  );
};
