import {
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  subMonths,
  endOfMonth,
  startOfYear,
  subYears,
  endOfYear,
  isSameDay,
  differenceInDays,
  subWeeks,
} from 'date-fns';
import { Preview, StaticRange } from 'react-date-range';

const allPresets: { [id: string]: StaticRange } = {
  yesterday: {
    label: 'Yesterday',
    range: () => ({
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subDays(new Date(), 1), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  this_week: {
    label: 'This week',
    range: () => ({
      startDate: startOfWeek(new Date()),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: startOfWeek(new Date()), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_week: {
    label: 'Last week',
    range: () => ({
      startDate: startOfWeek(subDays(new Date(), 7)),
      endDate: endOfWeek(subDays(new Date(), 7)),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfWeek(subDays(new Date(), 7)),
        endDate: endOfWeek(subDays(new Date(), 7)),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_7_days: {
    label: 'Last 7 days',
    range: () => ({
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subDays(new Date(), 7), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_14_days: {
    label: 'Last 14 days',
    range: () => ({
      startDate: subDays(new Date(), 14),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subDays(new Date(), 14), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  this_month: {
    label: 'This month',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: startOfMonth(new Date()), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_month: {
    label: 'Last month',
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_30_days: {
    label: 'Last 30 days',
    range: () => ({
      startDate: subDays(new Date(), 30),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subDays(new Date(), 30), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_3_months: {
    label: 'Last 3 months',
    range: () => ({
      startDate: subMonths(new Date(), 3),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subMonths(new Date(), 3), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_6_months: {
    label: 'Last 6 months',
    range: () => ({
      startDate: subMonths(new Date(), 6),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subMonths(new Date(), 6), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '3_months': {
    label: '3 months',
    range: () => ({
      startDate: subMonths(new Date(), 3),
      endDate: new Date(),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: subMonths(new Date(), 3),
        endDate: new Date(),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '3_months_ltv': {
    label: '3 months',
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: new Date(),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfMonth(subMonths(new Date(), 3)),
        endDate: new Date(),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '6_months': {
    label: '6 months',
    range: () => ({
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: subMonths(new Date(), 6),
        endDate: new Date(),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '6_months_ltv': {
    label: '6 months',
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 6)),
      endDate: new Date(),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfMonth(subMonths(new Date(), 6)),
        endDate: new Date(),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '1_year': {
    label: '1 year',
    range: () => ({
      startDate: startOfMonth(subYears(new Date(), 1)),
      endDate: new Date(),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: startOfMonth(subYears(new Date(), 1)), endDate: new Date() };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  this_year: {
    label: 'This year',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: startOfYear(new Date()), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_year: {
    label: 'Last year',
    range: () => ({
      startDate: startOfYear(subYears(new Date(), 1)),
      endDate: endOfYear(subYears(new Date(), 1)),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  last_365_days: {
    label: 'Last 365 days',
    range: () => ({
      startDate: subDays(new Date(), 365),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = { startDate: subDays(new Date(), 365), endDate: subDays(new Date(), 1) };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  '2_years': {
    label: '2 years',
    range: () => ({
      startDate: startOfMonth(subYears(new Date(), 2)),
      endDate: subDays(new Date(), 1),
    }),
    isSelected: (range) => {
      const definedRange = {
        startDate: startOfMonth(subYears(new Date(), 2)),
        endDate: subDays(new Date(), 1),
      };

      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
};

const get_presets = (presetIds: string[], labelsMap: { [name: string]: string }) => {
  return presetIds
    .map((id) => ({ id: id, preset: allPresets[id] }))
    .map((p) => ({ ...p.preset, label: labelsMap[p.id] === undefined ? p.preset.label : labelsMap[p.id] }));
};

const defaultLabelsMap: { [name: string]: string } = {};
export const defaultPresets: StaticRange[] = get_presets(
  [
    'yesterday',
    'this_week',
    'last_week',
    'last_7_days',
    'last_14_days',
    'this_month',
    'last_month',
    'last_30_days',
    'last_3_months',
    'last_6_months',
    'this_year',
    'last_year',
    'last_365_days',
  ],
  defaultLabelsMap
);

const ltvLabelsMap: { [name: string]: string } = {};
export const ltvPresets: StaticRange[] = get_presets(
  ['3_months_ltv', '6_months_ltv', '1_year', '2_years'],
  ltvLabelsMap
);

const adsOverTimeLabelsMap: { [name: string]: string } = {};
export const adsOverTimePresets: StaticRange[] = get_presets(
  ['3_months', '6_months', 'last_365_days'],
  adsOverTimeLabelsMap
);

export const compareToPresets = [
  {
    key: 'prev_period',
    label: 'Previous period',
    compareToPeriod: (referentRange: Preview) => {
      if (referentRange.startDate && referentRange.endDate) {
        const newEndDate = subDays(referentRange.startDate, 1);
        const diffInDays = differenceInDays(referentRange.endDate, referentRange.startDate);
        const newStartDate = subDays(newEndDate, diffInDays);
        return { startDate: newStartDate, endDate: newEndDate };
      } else {
        return null;
      }
    },
    isSelected: (referentRange: Preview, compareRange: Preview) => {
      if (
        referentRange.startDate &&
        referentRange.endDate &&
        compareRange.startDate &&
        compareRange.endDate
      ) {
        const newEndDate = subDays(referentRange.startDate, 1);
        const diffInDays = differenceInDays(referentRange.endDate, referentRange.startDate);
        const newStartDate = subDays(newEndDate, diffInDays);
        const definedRange = { startDate: newStartDate, endDate: newEndDate };

        return (
          isSameDay(compareRange.startDate, definedRange.startDate) &&
          isSameDay(compareRange.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  {
    key: 'prev_week',
    label: 'Same time previous week',
    compareToPeriod: (referentRange: Preview) => {
      if (referentRange.startDate && referentRange.endDate) {
        return {
          startDate: subWeeks(referentRange.startDate, 1),
          endDate: subWeeks(referentRange.endDate, 1),
        };
      } else {
        return null;
      }
    },
    isSelected: (referentRange: Preview, compareRange: Preview) => {
      if (
        referentRange.startDate &&
        referentRange.endDate &&
        compareRange.startDate &&
        compareRange.endDate
      ) {
        const definedRange = {
          startDate: subWeeks(referentRange.startDate, 1),
          endDate: subWeeks(referentRange.endDate, 1),
        };
        return (
          isSameDay(compareRange.startDate, definedRange.startDate) &&
          isSameDay(compareRange.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  {
    key: 'prev_month',
    label: 'Same time previous month',
    compareToPeriod: (referentRange: Preview) => {
      if (referentRange.startDate && referentRange.endDate) {
        return {
          startDate: subMonths(referentRange.startDate, 1),
          endDate: subMonths(referentRange.endDate, 1),
        };
      } else {
        return null;
      }
    },
    isSelected: (referentRange: Preview, compareRange: Preview) => {
      if (
        referentRange.startDate &&
        referentRange.endDate &&
        compareRange.startDate &&
        compareRange.endDate
      ) {
        const definedRange = {
          startDate: subMonths(referentRange.startDate, 1),
          endDate: subMonths(referentRange.endDate, 1),
        };
        return (
          isSameDay(compareRange.startDate, definedRange.startDate) &&
          isSameDay(compareRange.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
  {
    key: 'prev_year',
    label: 'Same time previous year',
    compareToPeriod: (referentRange: Preview) => {
      if (referentRange.startDate && referentRange.endDate) {
        return {
          startDate: subYears(referentRange.startDate, 1),
          endDate: subYears(referentRange.endDate, 1),
        };
      } else {
        return null;
      }
    },
    isSelected: (referentRange: Preview, compareRange: Preview) => {
      if (
        referentRange.startDate &&
        referentRange.endDate &&
        compareRange.startDate &&
        compareRange.endDate
      ) {
        const definedRange = {
          startDate: subYears(referentRange.startDate, 1),
          endDate: subYears(referentRange.endDate, 1),
        };
        return (
          isSameDay(compareRange.startDate, definedRange.startDate) &&
          isSameDay(compareRange.endDate, definedRange.endDate)
        );
      } else {
        return false;
      }
    },
  },
];
