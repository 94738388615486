import { capitalize } from './helpers';
import { MicrosoftIcon } from './icons/MicrosoftIcon';
import { FacebookIcon } from './icons/FacebookIcon';
import { GoogleIcon } from './icons/GoogleIcon';
import { PinterestIcon } from './icons/PinterestIcon';
import { ShopifyIcon } from './icons/ShopifyIcon';
import { TikTokIcon } from './icons/TikTokIcon';
import { WoocommerceIcon } from './icons/WooCommerceIcon';
import { ConnectionType, EcommerceType } from './models/connection';
import { SidebarGroup } from './routes';
import { KlaviyoIcon } from './icons/KlaviyoIcon';
import { GoogleAnalyticsIcon } from './icons/GoogleAnalyticsIcon';
import { CSSProperties } from 'react';
import { NoEcommerceIcon } from './icons/NoEcommerceIcon';

export const mod = (value: number, n: number): number => {
  return ((value % n) + n) % n;
};

export const renderConnectionIcon = (t: ConnectionType, platform?: EcommerceType, style?: any) => {
  switch (t) {
    case 'facebook':
      return <FacebookIcon style={style} />;
    case 'google':
      return <GoogleIcon style={style} />;
    case 'tiktok':
      return <TikTokIcon style={style} />;
    case 'pinterest':
      return <PinterestIcon style={style} />;
    case 'klaviyo':
      return <KlaviyoIcon style={style} />;
    case 'microsoft':
      return <MicrosoftIcon style={style} />;
    case 'google-analytics':
      return <GoogleAnalyticsIcon style={style} />;
    case 'ecommerce':
      return renderEcommerceIcon(platform);
  }
};

export const getEcommerceIcon = (platform?: EcommerceType) => {
  switch (platform) {
    case 'shopify':
      return ShopifyIcon;

    case 'woocommerce':
      return WoocommerceIcon;

    case 'noecommerce':
      return NoEcommerceIcon;

    default:
      return undefined;
  }
};

export const renderEcommerceIcon = (platform?: EcommerceType, style?: CSSProperties) => {
  switch (platform) {
    case 'shopify':
      return <ShopifyIcon style={style} />;

    case 'woocommerce':
      return <WoocommerceIcon style={style} />;

    case 'noecommerce':
      return <NoEcommerceIcon style={style} />;
  }
};

export const capitalizeConnectionType = (type?: ConnectionType | EcommerceType) => {
  if (type === 'tiktok') return 'TikTok';
  if (type === 'woocommerce') return 'WooCommerce';
  if (type === 'google-analytics') return 'Google Analytics';
  if (type === 'noecommerce') return 'Other';
  return capitalize(type);
};

export const capitalizeSidebarGroup = (group: SidebarGroup) =>
  group === 'tiktok' ? 'TikTok' : capitalize(group);

export const padNumber = (num: number, size: number): string => {
  let strNum = num.toString();
  while (strNum.length < size) {
    strNum = '0' + num;
  }
  return strNum;
};

export const shallowObjectEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const getPercentile = (percentile: number, array: number[]) => {
  array.sort((a, b) => a - b);

  let index = ((array.length - 1) * percentile) / 100;
  let lower = Math.floor(index);
  let upper = lower + 1;
  let weight = index % 1;

  if (upper >= array.length) return array[lower];
  return array[lower] * (1 - weight) + array[upper] * weight;
};

export const getUserInitials = (name: string) => {
  const nameParts = name.split(/[\s,.]+/).filter((part) => part.length > 0);
  if (nameParts.length === 0) return 'X';
  else if (nameParts.length === 1) return capitalize(nameParts[0].slice(0, 2));
  else return capitalize(nameParts[0][0]) + capitalize(nameParts[nameParts.length - 1][0]);
};

export const imgNotFoundSrc =
  'https://nikolas-bucket.s3.amazonaws.com/shop/16038/source_id/jOA0TVtWkBqJi2yvYnFxRETBD08wRUhG/no_photo.png';

export const setStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
  localStorage.setItem(key, value);
};

export const getStorage = (key: string) => {
  let sessionStorageValue = sessionStorage.getItem(key);
  if (sessionStorageValue !== null) return sessionStorageValue;

  let value = localStorage.getItem(key);

  if (value !== null) {
    sessionStorage.setItem(key, value);
  }

  return value;
};

export const removeStorage = (key: string) => {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
};

export const getDatesFromStorage = (cacheKey: string) => {
  const [period = '', cPeriod = ''] = (sessionStorage.getItem(cacheKey) || ':').split(':');
  const [pStartDate, pEndDate] = period.split('-').map((date) => (date ? new Date(date) : undefined));
  const [pcStartDate, pcEndDate] = cPeriod.split('-').map((date) => (date ? new Date(date) : undefined));
  const compareOption = sessionStorage.getItem(`${cacheKey}_compareOption`) || 'prev_period';

  return {
    startDate: pStartDate,
    endDate: pEndDate,
    startDateCompare: pcStartDate,
    endDateCompare: pcEndDate,
    compareOption,
  };
};
