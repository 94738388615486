import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';
import { blink } from 'styles/animations';

import { flexColumnCentered } from './helpers';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FlexColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexColumnEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const FlexRowCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexRowEnd = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexVerticalCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexSpaceEvenlyCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const BoxWithShadow = styled.div`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
  padding: 30px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  margin: 24px 0;
`;

export const RoundedBoxWithShadow = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  margin: 24px 0;
`;

export const RoundedBox = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
`;

export const RoundedBorderedBoxWithShadow = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  margin: 24px 0;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FlexReverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const FlexReverseCentered = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexColumnSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const FlexColumnHorizontalCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexSpaceBetweenCentered = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputSelectContainer = styled(motion.div)<{
  title: string;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  fullWidth?: boolean;
}>`
  border-radius: 8px;
  line-height: 1.3;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
  padding-left: 16px;
  padding-right: 16px;
  width: ${(props) => (props.fullWidth ? '100%' : 'max-content')};
  cursor: pointer;
  font-size: 12px;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.grayscale.line
      : props.color || props.theme.colors.grayscale.titleDark};
  position: relative;
  font-weight: 400;
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  background-color: ${(props) => (props.backgroundColor && !props.disabled ? props.backgroundColor : null)};

  &::after {
    content: '${(props) => props.title}';
    font-style: italic;
    color: ${(props) => props.theme.colors.grayscale.gray1};
    position: absolute;
    font-size: 10px;
    top: -22px;
    left: 0;
    white-space: nowrap;
  }
`;

export const MetricsBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
`;

export const GridRowContainer = styled.div<{ cols?: number; gap?: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.cols || 4}, 1fr);`};
  grid-gap: ${(props) => `${props.gap || 16}px`};
`;

export const SectionLoaderContainer = styled(FlexRowCentered)`
  height: 400px;
  width: 100%;
`;

export const CardLoaderContainer = styled(motion.div)<{ height: number }>`
  height: ${(props) => `${props.height}px`};
  border-radius: 16px;
  animation: ${(props) =>
      blink(
        rgba(props.theme.colors.grayscale.line, 0.2),
        rgba(props.theme.colors.grayscale.line, 0.3),
        rgba(props.theme.colors.grayscale.line, 0.4),
        rgba(props.theme.colors.grayscale.line, 0.3),
        rgba(props.theme.colors.grayscale.line, 0.2)
      )}
    2s infinite;
`;

export const CTAContainer = styled(FlexVerticalCentered)`
  border-radius: 8px;
  background-color: ${(props) => rgba(props.theme.colors.notifications.light, 0.5)};
  padding: 20px 16px;
  font-size: 11px;
  font-style: italic;
  color: ${(props) => props.theme.colors.notifications.border};
  width: 100%;
  font-weight: 500;
`;

export const GraphInfoContainer = styled(FlexVerticalCentered)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 8px;
  background-color: ${(props) => rgba(props.theme.colors.notifications.light, 0.5)};
  padding: 20px 16px;
  font-size: 11px;
  font-style: italic;
  color: ${(props) => props.theme.colors.notifications.border};
  font-weight: 500;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const FullPageContainer = styled(RelativeDiv)`
  width: 100%;
  height: 100%;
`;

export const FullPageFlexContainer = styled(FullPageContainer)`
  ${flexColumnCentered};
`;

export const FullPageCenteredContainer = styled(FullPageContainer)`
  ${flexColumnCentered};
  justify-content: center;
`;

export const RowGrid = styled.div<{ col: number; gap: number }>`
  width: 100%;
  margin: 64px 0;
  display: grid;
  grid-gap: ${(props) => `${props.gap}px`};
  grid-template-columns: ${(props) => `repeat(${props.col}, 1fr)`};
`;

export const RoundedLabel = styled(FlexRowCentered)`
  height: 20px;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 9px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.titleDark};
  color: ${(props) => props.theme.colors.grayscale.titleDark};
  cursor: pointer;
`;

export const YellowDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.notifications.default};
`;

export const InputContainer = styled.div<{
  radius?: number;
  borderWidth?: number;
  color?: string;
}>`
  border: ${(props) => props.borderWidth ?? 1}px solid;
  border-color: ${(props) => props.color ?? props.theme.colors.grayscale.line};
  border-radius: ${(props) => props.radius ?? 8 + 2 * (props.borderWidth ?? 1)}px;
  display: flex;
  position: relative;
`;

export const ErrorBox = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 20px 16px;

  color: ${(props) => props.theme.colors.alert.accentRed};
  background-color: ${(props) => props.theme.colors.alert.light3};

  font-size: 11px;
`;

export const ImportInProgressBox = styled.div`
  max-width: 500px;

  border-radius: 8px;
  padding: 20px 16px;
  margin-top: 10px;

  color: ${(props) => props.theme.colors.primary.blue};
  background-color: ${(props) => props.theme.colors.primary.lightBlue3};

  font-size: 11px;
  font-style: italic;
`;

export const Video = styled.iframe<{ height: number; width: number | string }>`
  border-radius: 16px;
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;
