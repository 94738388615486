import React from 'react';

export const PinterestIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M17.9251 23.9998H6.07486C2.71968 23.9998 0 21.2801 0 17.925V6.07469C0 2.72017 2.71968 0.000488281 6.07486 0.000488281H17.9251C21.2797 0.000488281 24 2.72017 24 6.07469V17.925C24 21.2801 21.2797 23.9998 17.9251 23.9998Z"
        fill="#EF2F36"
      />
      <path
        d="M10.9829 15.614C10.9922 15.5753 11.0047 15.536 11.0176 15.4898C11.1072 15.6043 11.1803 15.7172 11.2714 15.8134C12.4576 17.0653 15.0273 16.9304 16.6128 14.8899C17.9906 13.1143 18.0989 10.6024 17.9492 9.58319C17.6197 7.34644 15.8622 5.37525 13.0823 5.04735C10.754 4.77373 8.20502 5.67574 6.83446 7.90657C5.59702 9.92024 5.86428 12.2984 6.80026 13.5008C7.07479 13.8546 7.41304 14.1233 7.81965 14.2937C8.02632 14.3808 8.19777 14.3034 8.25474 14.0911C8.3314 13.8051 8.39408 13.5159 8.46504 13.2283C8.51114 13.0434 8.4034 12.9321 8.26355 12.7494C7.16181 11.3152 7.71657 8.17535 10.1567 7.00403C12.2856 5.98161 15.6411 6.66806 15.8224 9.8439C15.9135 11.4361 15.4308 13.6836 14.185 14.6206C12.8347 15.6376 11.2554 14.6474 11.5936 13.2547C12.0023 11.5667 12.5343 10.425 12.3333 9.55201C12.0064 8.14094 10.1386 8.27211 9.61858 9.98151C9.37824 10.7739 9.43314 11.5549 9.70767 12.3252C9.73253 12.394 9.75118 12.4575 9.73409 12.5338C8.58522 17.5481 8.4754 17.8652 8.44536 18.7903C8.43707 19.0586 8.44795 19.3257 8.45882 19.5929C8.78981 19.7466 9.12753 19.8869 9.4782 19.9998C9.57557 19.8402 9.67503 19.6821 9.76567 19.5176C10.3505 18.4425 10.4137 17.9286 10.9829 15.614Z"
        fill="white"
      />
    </svg>
  );
};
