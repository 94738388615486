import React from 'react';
import Link from 'next/link';
import { useIntercom } from 'react-use-intercom';

import { AlertIcon } from 'src/icons/AlertIcon';
import { UnderlinedLink } from 'src/components/utils/Typography';
import { API_BASE_URL } from 'src/api/utils';

import * as S from './style';

export const LimitedAccessErrorContainer: React.FC = () => {
  const { show } = useIntercom();

  return (
    <S.RefreshConnectionContainer>
      <AlertIcon style={{ height: 24, marginBottom: 8 }} />
      Your Access is restricted because your AppSumo Account has too many shops associated with it. <br />
      <Link href={`${API_BASE_URL}login`} passHref>
        <UnderlinedLink>Log in</UnderlinedLink>
      </Link>
      and disconnect some of your shops to regain full access. If you need help,{' '}
      <UnderlinedLink onClick={() => show()}>contact us</UnderlinedLink> directly.
    </S.RefreshConnectionContainer>
  );
};
