import { motion } from 'framer-motion';
import styled from 'styled-components';

import { FlexRowCentered } from 'src/components/utils/Containers';

export const Wrapper = styled.div`
  display: flex;
`;

export const SidebarWrapper = styled.div`
  height: 100%;
  background-color: #f7f7fc;
  position: fixed;
  top: 0;
  left: 0;
`;

export const MainContainer = styled(motion.div)<{ expanded: boolean; dark: boolean }>`
  min-height: 100vh;
  width: ${(props) => (props.expanded ? 'calc(100% - 272px)' : 'calc(100% - 72px)')};
  background-color: ${(props) => (props.dark ? '#0f152d' : props.theme.colors.grayscale.elementBg)};
  margin-left: ${(props) => (props.expanded ? '272px' : '72px')};
  transition: all 0.5s ease-in-out, background-color 0s;
`;

export const SplashContainer = styled(motion.div)`
  min-height: 100vh;
  width: 100%;
  background-color: #f7f7fc;
`;

export const LockedContainer = styled(FlexRowCentered)`
  background-color: ${(props) => props.theme.colors.notifications.light};
  padding: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayscale.titleDark};
`;

export const PlanLink = styled.span`
  font-weight: bold;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 4px;
`;
