import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { Button } from 'src/components/Button';
import { globalContext } from 'src/context';
import { FacebookIcon } from 'src/icons/FacebookIcon';
import { GoogleIcon } from 'src/icons/GoogleIcon';
import { ShopifyIcon } from 'src/icons/ShopifyIcon';
import { TikTokIcon } from 'src/icons/TikTokIcon';
import { WoocommerceIcon } from 'src/icons/WooCommerceIcon';
import { SidebarGroup, isSourceConnectAllowed } from 'src/routes';
import { capitalizeSidebarGroup, renderEcommerceIcon } from 'src/utils';

import * as S from './style';

interface Props {
  activeGroup: SidebarGroup;
  expanded?: boolean;
  authorized?: boolean;
}

export const ConnectionContainer: React.FC<Props> = ({ activeGroup, expanded, authorized }) => {
  const { state } = useContext(globalContext);
  const router = useRouter();

  const allowRefresh = isSourceConnectAllowed(state, activeGroup);

  const renderIcon = (activeGroup: SidebarGroup) => {
    switch (activeGroup) {
      case 'ecommerce':
        return renderEcommerceIcon(state.shop?.platform, { height: 20 });

      case 'facebook':
        return <FacebookIcon style={{ height: 20 }} />;

      case 'google':
        return <GoogleIcon style={{ height: 20 }} />;

      case 'tiktok':
        return <TikTokIcon style={{ height: 20 }} />;
    }
  };

  if (activeGroup === 'competitors' && state.plan !== 'ultimate') {
    return null;
  }

  return (
    <S.ConnectionContainer>
      {renderIcon(activeGroup)}
      <div style={{ width: 100, margin: '8px 0' }}>
        {capitalizeSidebarGroup(activeGroup)}{' '}
        {activeGroup === 'competitors' || activeGroup === 'ecommerce' ? '' : 'Ads'} Connection
      </div>
      {allowRefresh ? (
        <Button
          size="small"
          type="alert"
          style={{ margin: '0 auto' }}
          onClick={() =>
            activeGroup === 'competitors'
              ? router.push('/competitors/edit')
              : router.push(`/settings/integrations#${activeGroup}`)
          }
        >
          {activeGroup === 'competitors' ? 'Add now' : authorized ? 'Add account' : 'Connect now'}
        </Button>
      ) : (
        <div>
          Request from your admin to connect {capitalizeSidebarGroup(activeGroup)} as you don't have the
          required permission.
        </div>
      )}
    </S.ConnectionContainer>
  );
};
