import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: `'Poppins', sans-serif`,
  },
  styles: {
    global: {
      ol: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        paddingInlineStart: '40px',
      },
      ul: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        paddingInlineStart: '40px',
      },
    },
  },
});

export default theme;
