import React, { useContext } from 'react';
import { FlexColumnCentered, FlexRowCentered, FlexVerticalCentered } from 'src/components/utils/Containers';
import { globalContext } from 'src/context';
import { FacebookIcon } from 'src/icons/FacebookIcon';
import { GoogleIcon } from 'src/icons/GoogleIcon';
import { ShopifyIcon } from 'src/icons/ShopifyIcon';
import { TikTokIcon } from 'src/icons/TikTokIcon';
import { WoocommerceIcon } from 'src/icons/WooCommerceIcon';
import { SidebarGroup } from 'src/routes';
import { capitalizeSidebarGroup, renderEcommerceIcon } from 'src/utils';
import { motion } from 'framer-motion';
import { Flex } from '@chakra-ui/react';
import { HourglassIcon } from 'src/icons/HourglassIcon';

import * as S from './style';

interface Props {
  activeGroup: SidebarGroup;
  expanded?: boolean;
  progress: number;
}

export const ImportStatusContainer: React.FC<Props> = ({ activeGroup, expanded, progress }) => {
  const { state } = useContext(globalContext);

  const renderIcon = (activeGroup: SidebarGroup) => {
    switch (activeGroup) {
      case 'ecommerce':
        return renderEcommerceIcon(state.shop?.platform, { height: 20 });

      case 'facebook':
        return <FacebookIcon style={{ height: 20 }} />;

      case 'google':
        return <GoogleIcon style={{ height: 20 }} />;

      case 'tiktok':
        return <TikTokIcon style={{ height: 20 }} />;
    }

    return null;
  };

  return (
    <S.ConnectionContainer importing>
      {renderIcon(activeGroup)}

      {activeGroup === 'competitors' || activeGroup === 'business-navigator' ? (
        <FlexVerticalCentered style={{ width: 120, margin: '8px 0' }}>
          <motion.div
            animate={{ rotate: 180 }}
            transition={{ repeat: Infinity, duration: 1.5, repeatDelay: 0 }}
          >
            <HourglassIcon />
          </motion.div>
          <div style={{ marginLeft: 8 }}>Import In Progress</div>
        </FlexVerticalCentered>
      ) : (
        <div style={{ width: 120, margin: '8px 0' }}>
          {`${capitalizeSidebarGroup(activeGroup)} Import Status`}
        </div>
      )}

      {activeGroup === 'competitors' ? (
        <S.DescriptionText>Import might take several hours to finish.</S.DescriptionText>
      ) : activeGroup === 'business-navigator' ? (
        <S.DescriptionText>
          Your personalized insights will be ready for you the moment your import is complete.
        </S.DescriptionText>
      ) : (
        <FlexColumnCentered style={{ width: '100%' }}>
          <S.ImportStatusBar>
            <S.ImportProgressBar width={Math.min(100, progress * 100)} />
          </S.ImportStatusBar>
          <S.ImportStatusValue>{Math.min(Math.floor(progress * 100), 100).toFixed()}%</S.ImportStatusValue>
        </FlexColumnCentered>
      )}
    </S.ConnectionContainer>
  );
};
