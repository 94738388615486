import React from 'react';

export const KlaviyoIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="12" cy="12" r="11.8" fill="white" stroke="#DFE0EB" stroke-width="0.4" />
      <path d="M19.9401 17H5V7H19.9401L16.7964 12L19.9401 17Z" fill="#010202" />
    </svg>
  );
};
