import React from 'react';
import { SectionLoaderContainer } from './Containers';
import { Loader } from './helpers';

interface Props {
  height?: number | string;
  width?: number | string;
  light?: boolean;
}

export const SectionLoader: React.FC<Props> = ({ height, light, width }) => {
  return (
    <SectionLoaderContainer style={{ height: height, width: width }}>
      <Loader width={50} height={50} light={light} />
    </SectionLoaderContainer>
  );
};
