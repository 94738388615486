import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  left: { rotate: 180 },
  right: { rotate: 0 },
};

export const CollapseIcon: React.FC<{
  style?: React.CSSProperties;
  expanded: boolean;
  onClick: () => void;
}> = ({ style, expanded, onClick }) => {
  return (
    <motion.svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      variants={variants}
      animate={expanded ? 'right' : 'left'}
      initial={'left'}
      // transition={{ duration: 0.5 }}
      onClick={onClick}
    >
      <path
        d="M17.3525 6.40979L2.65252 6.40979M17.3525 0.650025L9.05252 0.650024M17.3084 12L9.09507 12M4.13332 4.79983L2 6.33584L4.13332 7.87185"
        stroke="#6E7191"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </motion.svg>
  );
};
