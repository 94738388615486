import React from 'react';

export const GoogleIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="12" cy="12" r="11.85" fill="white" stroke="#D9DBE9" strokeWidth="0.3" />
      <path
        d="M5.13103 14.7602L9.91224 6.5752C10.5195 6.93271 13.5817 8.62741 14.0758 8.94966L9.29504 17.1347C8.77268 17.8254 4.46914 15.8084 5.13103 14.7602Z"
        fill="#FBBC04"
      />
      <path
        d="M18.878 14.7604L14.0973 6.57534C13.429 5.46358 11.9895 5.05939 10.8072 5.71632C9.62497 6.37324 9.26497 7.78839 9.93332 8.9503L14.714 17.1353C15.3824 18.2471 16.8219 18.6513 18.0041 17.9943C19.1353 17.3374 19.5464 15.8721 18.878 14.7608V14.7604Z"
        fill="#4285F4"
      />
      <path
        d="M7.1996 18.3103C8.52497 18.3103 9.59939 17.2625 9.59939 15.9701C9.59939 14.6776 8.52497 13.6299 7.1996 13.6299C5.87423 13.6299 4.7998 14.6776 4.7998 15.9701C4.7998 17.2625 5.87423 18.3103 7.1996 18.3103Z"
        fill="#34A853"
      />
    </svg>
  );
};
