import React from 'react';

export const RobotSidebarIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.748 16.0732C18.6369 16.0732 20.1682 14.5419 20.1682 12.6531V11.9701C20.1682 11.7169 19.963 11.5117 19.7098 11.5117L2.29451 11.5117C2.04138 11.5117 1.83618 11.7169 1.83618 11.9701V12.6531C1.83618 14.5419 3.36743 16.0732 5.25632 16.0732H7.94349L11.0001 19.2497L14.0566 16.0732H16.748Z"
        fill="#FFF6C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83333 3.42014C1.83333 1.53125 3.36458 0 5.25348 0H16.7465C18.6354 0 20.1667 1.53125 20.1667 3.42014V9.625C20.1667 9.87813 19.9615 10.0833 19.7083 10.0833H2.29167C2.03854 10.0833 1.83333 9.87813 1.83333 9.625V3.42014ZM8.32868 6.11486C8.32868 6.8635 7.72179 7.4704 6.97315 7.4704C6.22451 7.4704 5.61761 6.8635 5.61761 6.11486C5.61761 5.36622 6.22451 4.75933 6.97315 4.75933C7.72179 4.75933 8.32868 5.36622 8.32868 6.11486ZM15.0242 7.4704C15.7728 7.4704 16.3797 6.8635 16.3797 6.11486C16.3797 5.36622 15.7728 4.75933 15.0242 4.75933C14.2755 4.75933 13.6686 5.36622 13.6686 6.11486C13.6686 6.8635 14.2755 7.4704 15.0242 7.4704Z"
        fill="#F1B43C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.458334 3.66667C0.711464 3.66667 0.916667 3.87187 0.916667 4.125L0.916667 7.79167C0.916667 8.0448 0.711464 8.25 0.458333 8.25C0.205203 8.25 -1.10647e-08 8.0448 0 7.79167L1.60275e-07 4.125C1.7134e-07 3.87187 0.205203 3.66667 0.458334 3.66667Z"
        fill="#F1B43C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5417 3.66667C21.7948 3.66667 22 3.87187 22 4.125V7.79167C22 8.0448 21.7948 8.25 21.5417 8.25C21.2885 8.25 21.0833 8.0448 21.0833 7.79167V4.125C21.0833 3.87187 21.2885 3.66667 21.5417 3.66667Z"
        fill="#F1B43C"
      />
    </svg>
  );
};
