import React from 'react';

export const TikTokNoBgIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90047 15.8624C4.5259 15.3707 4.32259 14.7699 4.32351 14.1517C4.32351 12.5921 5.58915 11.3265 7.15059 11.3265C7.44181 11.3265 7.7312 11.3714 8.00868 11.4593V8.04243C7.68449 7.99847 7.35756 7.97924 7.03062 7.98657V10.6451C6.75313 10.5581 6.46375 10.5133 6.17252 10.5133C4.61109 10.5133 3.34544 11.7789 3.34544 13.3394C3.34544 14.442 3.97826 15.3972 4.90047 15.8624ZM14.5475 3.97078C13.8762 3.23814 13.4366 2.29122 13.3405 1.24446V0.814941H12.4137C12.6472 2.1456 13.443 3.2821 14.5475 3.97078V3.97078ZM13.3405 7.34276C14.6537 8.28054 16.2628 8.83277 18.0001 8.83277V5.49102C17.6713 5.49102 17.3434 5.45713 17.022 5.38845V8.01954C15.2847 8.01954 13.6757 7.46731 12.3624 6.52861V13.3476C12.3624 16.7599 9.59577 19.5256 6.18258 19.5256C4.90871 19.5256 3.72551 19.141 2.74194 18.4807C3.8638 19.6273 5.4289 20.3389 7.16067 20.3389C10.5739 20.3389 13.3405 17.5741 13.3405 14.1618V7.34276Z"
        fill="#EE2659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.362 6.52836C13.6752 7.46706 15.2843 8.01929 17.0216 8.01929V5.3882C16.0508 5.18214 15.1927 4.6757 14.5471 3.97053C13.4426 3.28185 12.6468 2.14535 12.4133 0.814691H9.97722V14.1615C9.97172 15.7175 8.70793 16.9767 7.15016 16.9767C6.23253 16.9767 5.41654 16.5399 4.90003 15.8622C3.97782 15.397 3.34501 14.4418 3.34501 13.3392C3.34501 11.7786 4.61065 10.513 6.17209 10.513C6.47156 10.513 6.7591 10.5588 7.02927 10.6449V7.98632C3.67653 8.05592 0.979492 10.7932 0.979492 14.1615C0.979492 15.843 1.65171 17.3668 2.74151 18.4805C3.72508 19.1408 4.90828 19.5254 6.18215 19.5254C9.59533 19.5254 12.362 16.7597 12.362 13.3483V6.52836"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02876 7.98584V7.22847C6.74761 7.19001 6.46462 7.17078 6.18073 7.17078C2.76754 7.17078 0 9.93649 0 13.3469C0 15.4862 1.08798 17.3709 2.74009 18.48C1.65029 17.3664 0.978984 15.8425 0.978984 14.1611C0.978984 10.7928 3.6751 8.05544 7.02876 7.98584V7.98584ZM12.4118 0.814213C12.3889 0.686917 12.3724 0.559626 12.3605 0.430498V6.60266e-05H8.99773V13.3469C8.99132 14.9038 7.72844 16.1639 6.17067 16.1639C5.71277 16.1639 5.28141 16.054 4.89861 15.8617C5.41512 16.5394 6.2311 16.9762 7.14873 16.9762C8.70651 16.9762 9.9703 15.717 9.97579 14.1611V0.814213H12.4118ZM17.0201 5.38772V4.67706C16.1446 4.67889 15.2874 4.43345 14.5456 3.97005C15.2023 4.68896 16.0677 5.18441 17.0201 5.38772Z"
        fill="#47C1BE"
      />
    </svg>
  );
};
