import styled, { DefaultTheme } from 'styled-components';

import { MetricSign, AccountCheckResult } from 'src/models/utils';
import { FlexRowCentered } from '../utils/Containers';

export const LabelBase = styled.div`
  font-size: 9px;
  text-transform: uppercase;
  height: 16px;
  font-weight: 500;
  border-radius: 12px;
`;

export const CenteredLabel = styled(LabelBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const DefaultLabel = styled(CenteredLabel)`
  padding: 0 15px;
  background-color: ${(props) => props.theme.colors.grayscale.inputBg};
  color: ${(props) => props.theme.colors.grayscale.body};
`;

export const TestLabel = styled(CenteredLabel)<{ type: AccountCheckResult; width?: number }>`
  min-width: ${(props) => (props.width ? `${props.width}px` : '70px')};
  background-color: ${(props) =>
    props.type === 'passed'
      ? props.theme.colors.primary.lightBlue2
      : props.type === 'failed'
      ? props.theme.colors.alert.light2
      : props.theme.colors.grayscale.inputBg};

  color: ${(props) =>
    props.type === 'passed'
      ? props.theme.colors.primary.blue
      : props.type === 'failed'
      ? props.theme.colors.alert.dark
      : props.theme.colors.grayscale.body};
  padding: 0 8px;
`;

export const MetricLabel = styled(CenteredLabel)<{ type: MetricSign }>`
  min-width: 60px;
  color: ${(props) => handleColorType(props.type, props.theme)};
  background-color: ${(props) => handleBgColorType(props.type, props.theme)};
  padding: 0 8px;
  line-height: 2px;
`;

export const BorderLabel = styled(CenteredLabel)`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.primary.darkBlue1};
  color: ${(props) => props.theme.colors.primary.darkBlue1};
  font-weight: 600;
  padding: 0 8px;
  line-height: 2px;
  width: fit-content;
`;

export const handleColorType = (type: MetricSign, theme: DefaultTheme) => {
  switch (type) {
    case 'neutral':
      return theme.colors.grayscale.body;

    case 'bad':
      return theme.colors.alert.dark;

    case 'very_bad':
      return theme.colors.alert.dark;

    case 'extremely_bad':
      return 'white';

    case 'good':
      return theme.colors.primary.blue;

    case 'very_good':
      return theme.colors.primary.blue;

    case 'extremely_good':
      return 'white';
  }
};

export const handleBgColorType = (type: MetricSign, theme: DefaultTheme) => {
  switch (type) {
    case 'neutral':
      return theme.colors.grayscale.inputBg;

    case 'bad':
      return theme.colors.alert.light3;

    case 'very_bad':
      return theme.colors.alert.light2;

    case 'extremely_bad':
      return theme.colors.alert.dark;

    case 'good':
      return theme.colors.primary.lightBlue3;

    case 'very_good':
      return theme.colors.primary.lightBlue2;

    case 'extremely_good':
      return theme.colors.primary.blue;
  }
};

export const handleBorderColorType = (type: MetricSign, theme: DefaultTheme) => {
  switch (type) {
    case 'neutral':
      return theme.colors.grayscale.line;

    case 'bad':
      return theme.colors.alert.light2;

    case 'very_bad':
      return theme.colors.alert.light2;

    case 'extremely_bad':
      return theme.colors.alert.light2;

    case 'good':
      return theme.colors.primary.lightBlue1;

    case 'very_good':
      return theme.colors.primary.lightBlue1;

    case 'extremely_good':
      return theme.colors.primary.lightBlue1;
  }
};

export const NewLabel = styled(CenteredLabel)`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.notifications.default};
  color: ${(props) => props.theme.colors.notifications.default};
  font-weight: 500;
  padding: 0 8px;
  text-transform: uppercase;
`;

export const NotificationLabel = styled(CenteredLabel)`
  background-color: ${(props) => props.theme.colors.notifications.light};
  color: ${(props) => props.theme.colors.notifications.border};
  font-weight: 500;
  padding: 0 8px;
  text-transform: uppercase;
`;

export const ClickableLabel = styled(NotificationLabel)`
  &:hover {
    background-color: ${(props) => props.theme.colors.notifications.light2};
  }
`;

export const FilterLabel = styled(FlexRowCentered)`
  border: 1px solid ${(props) => props.theme.colors.primary.blue};
  border-radius: 16px;
  padding: 2px 12px;
  background-color: ${(props) => props.theme.colors.primary.lightBlue3};
  color: ${(props) => props.theme.colors.primary.blue};
  font-size: 11px;
  max-width: 100%;
`;

export const FilterLabelText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
