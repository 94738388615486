import Link from 'next/link';
import React, { useContext } from 'react';
import { UnderlinedLink } from 'src/components/utils/Typography';
import { AlertIcon } from 'src/icons/AlertIcon';
import { SidebarGroup, isSourceConnectAllowed } from 'src/routes';

import * as S from './style';
import { capitalizeSidebarGroup } from 'src/utils';
import { useRouter } from 'next/router';
import { globalContext } from 'src/context';

interface Props {
  activeGroup: SidebarGroup;
}

export const RefreshConnectionContainer: React.FC<Props> = ({ activeGroup }) => {
  const { state } = useContext(globalContext);

  const router = useRouter();

  const click = () => {
    router.push(`/settings/integrations#${activeGroup}`);
  };

  const allowRefresh = isSourceConnectAllowed(state, activeGroup);

  return (
    <S.RefreshConnectionContainer>
      <AlertIcon style={{ height: 24, marginBottom: 8 }} />
      {allowRefresh ? (
        <div>
          Some of your {capitalizeSidebarGroup(activeGroup)} connections need to be refreshed. Click{' '}
          <UnderlinedLink onClick={click}>here</UnderlinedLink> to fix.
        </div>
      ) : (
        <div>
          Some of your {capitalizeSidebarGroup(activeGroup)} connections need to be refreshed. Request from
          your admin to handle the issue as you don't have the required permission.
        </div>
      )}
    </S.RefreshConnectionContainer>
  );
};
