import { useEffect } from 'react';
import { useScrollBlock } from 'src/hooks/scroll';
import { CloseIcon } from 'src/icons/CloseIcon';
import * as S from './style';

interface ModalProps {
  onClose?: () => void;
  layoutId?: string;
  closeButton?: boolean;
  transparent?: boolean;
  scrollable?: boolean;
  isLarge?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  layoutId,
  closeButton,
  transparent,
  children,
  scrollable = true,
  isLarge = false,
}) => {
  const { blockScroll, allowScroll } = useScrollBlock();

  blockScroll();

  const onClick = () => {
    allowScroll();
    onClose && onClose();
  };

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);

  return (
    <>
      <S.ModalContainer>
        <S.ModalContent
          layoutId={layoutId}
          transparent={transparent}
          scrollable={scrollable}
          isLarge={isLarge}
        >
          {closeButton && (
            <S.CloseButtonContainer
              onClick={onClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.2, duration: 0.1 }}
            >
              <CloseIcon />
            </S.CloseButtonContainer>
          )}
          {children}
        </S.ModalContent>
      </S.ModalContainer>
      <S.ModalBackdrop
        onClick={onClick}
        variants={{
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.16,
            },
          },
          visible: {
            opacity: 0.6,
            transition: {
              duration: 0.1,
            },
          },
        }}
        initial="hidden"
        exit="hidden"
        animate="visible"
      />
    </>
  );
};
