import { JwtPayload, jwtDecode } from 'jwt-decode';
import { NextRouter } from 'next/router';
// import * as Sentry from "@sentry/browser";
import * as Sentry from '@sentry/nextjs';

import { getStorage, removeStorage, setStorage } from '../utils';
import { getResource } from '../api';
import { API_BASE_URL } from '../api/utils';
import { ShopBasicInfo } from '../models/shop';

interface ExtendedJwt extends JwtPayload {
  name?: string;
  exp: number;
  iat: number;
  sub: string;
}

const SHOP_PREFIX_LENGTH = 8;
const USER_PREFIX_LENGTH = 8;

export const getTokenData = (key: string) => {
  let token = getStorage(key);
  if (token === null) return 'null';
  let decoded = jwtDecode<ExtendedJwt>(token);
  return `sub: ${decoded.sub} name: ${decoded.name} iat: ${decoded.iat} exp: ${decoded.exp}`;
};

export const resolveTokens = async (redirect: string | undefined, router: NextRouter | undefined) => {
  // set all data we currently have about user
  let shopId = undefined;
  let shopUserId = undefined;
  let userId = undefined;
  let claim = false;

  let currTime = new Date().getTime();

  let shopToken = getStorage('token');
  let decodedShopToken = shopToken !== null ? jwtDecode<ExtendedJwt>(shopToken) : undefined;
  if (!decodedShopToken || decodedShopToken.exp * 1000 < currTime || decodedShopToken.name === undefined) {
    decodedShopToken = undefined;
    removeStorage('token');
  } else {
    shopId = decodedShopToken.sub.slice(SHOP_PREFIX_LENGTH);
    shopUserId = decodedShopToken.name.slice(USER_PREFIX_LENGTH);
    if (shopUserId.toLowerCase() === 'none') {
      shopUserId = undefined;
    } else if (shopUserId.toLowerCase() === 'claim') {
      claim = true;
      shopUserId = undefined;
    }
  }

  Sentry.setContext('shopToken', {
    shopId: shopId,
    shopUserId: shopUserId,
    claim: claim,
    expires_at: decodedShopToken ? new Date(decodedShopToken.exp * 1000) : undefined,
  });

  let userToken = getStorage('userToken');
  let decodedUserToken = userToken !== null ? jwtDecode<ExtendedJwt>(userToken) : undefined;
  if (!decodedUserToken || decodedUserToken.exp * 1000 < currTime) {
    decodedShopToken = undefined;
    removeStorage('userToken');
  } else {
    userId = decodedUserToken.sub.slice(USER_PREFIX_LENGTH);
  }

  Sentry.setContext('userToken', {
    userId: userId,
    expires_at: decodedUserToken ? new Date(decodedUserToken.exp * 1000) : undefined,
  });

  if (shopToken?.startsWith('"')) {
    shopToken = shopToken.slice(1, shopToken.length - 1);
  }
  if (userToken?.startsWith('"')) {
    userToken = userToken.slice(1, userToken.length - 1);
  }

  const changeRoute = (route: string, router: NextRouter | undefined) => {
    if (router === undefined) {
      document.location.href = route;
    } else {
      router.push(route);
    }
  };

  // if we have both user id and shop id, we try to login the user to that shop
  if (shopId && userId) {
    // we have to check if the user to which the token was issued is the same as this user.
    // if so, let him through
    if (shopUserId === userId) {
      if (redirect) {
        changeRoute(`/?redirect=${redirect}`, router);
      } else {
        changeRoute('/', router);
      }
      return;
      // If not check if this shop token was issued for any specific user.
      // Try to obtain shop token issued for this user, or claim the shop for user if suitable.
    } else if (shopUserId === undefined) {
      // If it is shopify login from ownerless store, claim store
      if (claim) {
        document.location.href = `${API_BASE_URL}user-shop/claim-shop?claim_token=${shopToken}&token=${userToken}`;
        return;
      } else {
        // try to obtain shop token issued for this user.
        // if successfull log in.
        const newShopToken = await getResource<string>(
          `user-shop/get-shop-token/${shopId}`,
          true,
          true,
          {},
          true
        );
        if (newShopToken !== '') {
          setStorage('token', JSON.stringify(newShopToken));
          if (redirect) {
            changeRoute(`/?redirect=${redirect}`, router);
          } else {
            changeRoute('/', router);
          }
          return;
        }
      }
    }
    // If we got here it means user token and shop token are not compatible.
    // In that case, keep the token we received the last and delete the other one.
    // Require user to either login/signup or select shop depending on which token was deleted.
    if (decodedShopToken && decodedUserToken && decodedUserToken.iat < decodedShopToken.iat) {
      removeStorage('userToken');

      // check if store has owner
      const shopBasicInfo = await getResource<ShopBasicInfo>(`shop/get-basic-info?token=${shopToken}`);
      changeRoute(`/signup?ownerEmail=${shopBasicInfo.ownerEmail}`, router);
    } else {
      removeStorage('token');
      changeRoute('/select', router);
    }
    // if we only have shop token
  } else if (shopId) {
    removeStorage('userToken');

    // check if token is issued for user. Require login
    if (shopUserId) {
      document.location.href = `/logout?user_id=${shopUserId}`;
    } else {
      // check if store has owner
      const shopBasicInfo = await getResource<ShopBasicInfo>(`shop/get-basic-info?token=${shopToken}`);
      changeRoute(`/signup?ownerEmail=${shopBasicInfo.ownerEmail}`, router);
    }
    // if only user_id, require select shop
  } else if (userId) {
    removeStorage('token');
    changeRoute('/select?auto_select=true', router);
  } else {
    document.location.href = '/login';
  }
};
