import { AuthData, AuthType, ConnectionType, Status, StatusJson } from 'src/models/connection';
import { GlobalContextType } from './model';
import { Shop } from 'src/models/shop';
import { CompetitorJson } from 'src/models/competitors';
import { NextStepsCounter, User } from 'src/models/user';
import { AppType, ModuleType, PricingPlan } from 'src/models/pricing';
import { IRoute, SidebarGroup } from 'src/routes';
import { getResource } from 'src/api';
import { Dispatch } from 'react';
import { ScopeType } from 'src/appConfig';
import { AdAccount, PlatformUserAccount } from 'src/models/accounts';
import { DateSelection } from 'src/models/utils';
import { compareToPresets } from 'src/components/DatetimePicker/presets';

export type StatusUpdatePayload = { [type in ConnectionType]?: Status };

export type AuthUpdatePayload = { [type in AuthType]?: AuthData };

export type Action =
  | { type: 'init' }
  | { type: 'toggle' }
  | { type: 'status-update'; payload: StatusUpdatePayload }
  | { type: 'set-competitors'; payload: CompetitorJson[] }
  | { type: 'set-shop'; payload: Shop }
  | { type: 'set-account-auth'; payload: AuthUpdatePayload }
  | { type: 'set-user'; payload: User }
  | { type: 'reset'; payload: GlobalContextType }
  | { type: 'lock' }
  | { type: 'set-plan'; payload: PricingPlan | null }
  | { type: 'set-appsumo-plan'; payload: string | null }
  | { type: 'set-next-steps-counter'; payload: NextStepsCounter }
  | { type: 'set-standalone-billing'; payload: boolean }
  | { type: 'set-modules'; payload: ModuleType[] }
  | { type: 'set-scope'; payload: ScopeType[] }
  | { type: 'set-app'; payload: AppType }
  | { type: 'refresh-import-progress' }
  | { type: 'refresh-auth' }
  | { type: 'show-locked-modal'; payload: { route: IRoute; group: SidebarGroup } | null }
  | { type: 'show-intro-modal'; payload: boolean }
  | { type: 'set-dark-bg'; payload: boolean }
  | { type: 'set-data-loaded'; payload: boolean }
  | { type: 'set-dates'; payload: DateSelection };

export type Reducer = (prevState: GlobalContextType, action: Action) => GlobalContextType;

export const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      return { ...state, sidebarExpanded: !state.sidebarExpanded };
    case 'status-update':
      return { ...state, status: { ...state.status, ...action.payload } };
    case 'set-competitors':
      return { ...state, competitors: action.payload };
    case 'set-shop':
      return { ...state, shop: action.payload };
    case 'set-account-auth':
      return { ...state, adsAccountAuth: { ...state.adsAccountAuth, ...action.payload } };
    case 'set-user':
      return { ...state, user: action.payload };
    case 'reset':
      return { ...action.payload };
    case 'lock':
      return { ...state, locked: true };
    case 'set-plan':
      return { ...state, plan: action.payload };
    case 'set-appsumo-plan':
      return { ...state, appsumoPlan: action.payload };
    case 'set-scope':
      return { ...state, scope: action.payload };
    case 'set-standalone-billing':
      return { ...state, standaloneBilling: action.payload };
    case 'set-modules':
      return { ...state, modules: action.payload };
    case 'set-app':
      return { ...state, app: action.payload };
    case 'set-next-steps-counter':
      return { ...state, nextStepsCounter: action.payload };
    case 'refresh-import-progress':
      return { ...state, refreshImportProgress: state.refreshImportProgress + 1 };
    case 'refresh-auth':
      return { ...state, refreshAuth: state.refreshAuth + 1 };
    case 'show-locked-modal':
      return { ...state, showLockedModal: action.payload };
    case 'show-intro-modal':
      return { ...state, showIntroModal: action.payload };
    case 'set-dark-bg':
      return { ...state, darkBg: action.payload };
    case 'set-data-loaded':
      return { ...state, dataLoaded: action.payload };
    case 'set-dates':
      return { ...state, dates: action.payload };
    default:
      return state;
  }
};

export const mutateAuth = (group: AuthType, dispatch: Dispatch<Action>) => {
  let url =
    group === 'ecommerce'
      ? 'ecommerce/authorized'
      : `${group}/${group === 'google-analytics' ? 'properties' : 'ad-accounts'}/connected`;

  const advertisingMutateAuth = async () => {
    let payload: AuthUpdatePayload = {};

    let connectedAccounts = await getResource<AdAccount[]>(url, true);
    let needsRefresh = connectedAccounts.some((acc) => !acc.hasActiveCredentials || !acc.hasRequiredScope);
    let hasFullScope = connectedAccounts.some((acc) => !acc.hasActiveCredentials || !acc.hasFullScope);

    let platformUsers = await getResource<PlatformUserAccount[]>(`${group}/oauth2/platform-users`, true);

    let connected = platformUsers.length > 0 || connectedAccounts.length > 0;
    let userConnected =
      platformUsers.length > 0 || connectedAccounts.filter((acc) => acc.connectedByCurrentUser).length > 0;

    payload[group] = {
      connected: connected,
      hasFullScope: hasFullScope,
      needsRefresh: needsRefresh,
      href: '',
      userConnected: userConnected,
    };
    dispatch({
      type: `set-account-auth`,
      payload: payload,
    });
  };

  if (
    ['facebook', 'google', 'tiktok', 'pinterest', 'microsoft', 'klaviyo', 'google-analytics'].includes(group)
  ) {
    return advertisingMutateAuth().catch(() => {});
  }

  return getResource<boolean>(url, true)
    .then((data) => {
      let payload: AuthUpdatePayload = {};
      payload[group] = {
        connected: data,
        needsRefresh: false,
        hasFullScope: true,
        href: '',
        userConnected: false,
      };
      dispatch({
        type: `set-account-auth`,
        payload: payload,
      });
    })
    .catch(() => {});
};

export const mutateImportStatus = (group: ConnectionType, dispatch: Dispatch<Action>) => {
  let url = group === 'business-navigator' ? 'shop/next-steps-import-status' : `shop/import-status/${group}`;

  return getResource<StatusJson>(url, true)
    .then((data) => {
      let statusUpdate: StatusUpdatePayload = {};
      statusUpdate[group] = data;
      dispatch({ type: 'status-update', payload: statusUpdate });
    })
    .catch(() => {});
};

export const mutateDates = (newDates: DateSelection, dispatch: Dispatch<Action>) => {
  const { startDate, endDate, startDateCompare, endDateCompare, compareOption } = newDates;

  if (!startDateCompare || !endDateCompare) {
    const compareToPreset = compareToPresets.find((preset) => preset.key === compareOption);
    if (compareToPreset) {
      const comparePeriod = compareToPreset?.compareToPeriod({ startDate, endDate });
      dispatch({
        type: 'set-dates',
        payload: {
          startDate,
          endDate,
          startDateCompare: comparePeriod?.startDate,
          endDateCompare: comparePeriod?.endDate,
          compareOption,
        },
      });
    }
  } else {
    dispatch({ type: 'set-dates', payload: newDates });
  }
};
