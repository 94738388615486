import { NewLabelProps } from './interface';

import * as S from './style';

export const NewLabel: React.FC<NewLabelProps> = ({ newUntil, style }) => {
  if (!newUntil || new Date() > new Date(newUntil)) return null;
  return (
    <div style={style}>
      <S.NewLabel>new</S.NewLabel>
    </div>
  );
};
