import React from 'react';

export const LockIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75009 0C2.41033 0 1.32422 1.08928 1.32422 2.43308L1.32424 3.15963C0.578616 3.2469 0 3.88259 0 4.65387V7.4956C0 8.32651 0.67155 9.00006 1.5 9.00006H6C6.82845 9.00006 7.5 8.32651 7.5 7.4956V4.65387C7.5 3.88267 6.92149 3.24703 6.17597 3.15966V2.43308C6.17597 1.08934 5.08986 0 3.75009 0ZM5.12597 3.14941V2.43308C5.12597 1.67094 4.50999 1.05306 3.75011 1.05306C2.99023 1.05306 2.37426 1.67092 2.37426 2.43308V3.14941H5.12597ZM3.75 6.78926C3.46003 6.78926 3.225 6.55353 3.225 6.2627V5.88658C3.225 5.59575 3.46003 5.36002 3.75 5.36002C4.03997 5.36002 4.275 5.59575 4.275 5.88658V6.2627C4.275 6.55353 4.03997 6.78926 3.75 6.78926Z"
        fill="#F1B43C"
      />
    </svg>
  );
};
