import React from 'react';

export const CheckmarkIcon: React.FC<{ style?: React.CSSProperties; color?: string }> = ({
  style,
  color = '#3B90BD',
}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M6 10.1567L8.82843 12.9852L14.4853 7.32831" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
