import styled from 'styled-components';

export const PageTitleText = styled.div<{ light?: boolean }>`
  font-size: 26px;
  font-weight: 700;
  color: ${(props) =>
    props.light ? props.theme.colors.grayscale.offWhite : props.theme.colors.primary.darkBlue2};
  line-height: 1.3;
`;

export const BoxTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary.darkBlue2};
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const PageDescriptionText = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.grayscale.gray1};
  font-weight: 600;
`;

export const RegularDescription = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayscale.body};
`;

export const SectionTitleText = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary.darkBlue2};
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const Subtitle = styled.div`
  font-size: 9px;
  font-style: italic;
  color: ${(props) => props.theme.colors.grayscale.gray1};
`;

export const DescriptionText = styled.div<{ light?: boolean }>`
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: ${(props) => (props.light ? props.theme.colors.grayscale.gray1 : props.theme.colors.grayscale.body)};
`;

export const LinkText = styled.div`
  font-size: 11px;
  font-style: italic;
  color: ${(props) => props.theme.colors.primary.blue};
  cursor: pointer;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  width: content-fit;
  height: content-fit;
`;

export const CardTitle = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary.darkBlue2};
  font-weight: 500;
  letter-spacing: 0.25px;
  font-style: normal;
`;

export const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const AlertText = styled.div<{ light?: boolean }>`
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: ${(props) => props.theme.colors.alert.dark};
`;

export const TextAlignCenter = styled.div`
  text-align: center;
`;

export const UnderlinedLink = styled.span`
  text-decoration: underline;
  font-weight: 700;
  color: ${(props) => props.theme.colors.grayscale.titleDark};
  cursor: pointer;
`;
