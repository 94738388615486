import React from 'react';

export const TikTokIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 17.9082V17.9092C0.0143738 18.4424 0.0188073 18.9786 0.108737 19.5084C0.204033 20.069 0.340257 20.6156 0.600151 21.1244C0.951514 21.8131 1.43406 22.3946 2.04317 22.8696C2.4805 23.2107 2.95824 23.4815 3.48708 23.6523C4.25822 23.901 5.05377 23.9964 5.86094 24.001C6.36454 24.0042 6.86781 24.0168 7.37159 24.0147C11.0301 23.9993 14.6888 24.0411 18.3474 23.9921C18.8329 23.9855 19.3104 23.9263 19.7869 23.8392C20.7002 23.672 21.5073 23.2824 22.1978 22.6663C23.0026 21.9485 23.5456 21.07 23.7907 20.0098C23.9511 19.3167 23.9912 18.6136 23.9981 17.9081V17.7673C23.9981 17.7127 23.9773 5.77867 23.9754 5.62093C23.9701 5.18762 23.9381 4.75757 23.8569 4.33121C23.7581 3.81166 23.6185 3.3054 23.3697 2.83536C23.107 2.33877 22.778 1.89225 22.371 1.50206C21.7475 0.90424 21.0281 0.481474 20.1905 0.257841C19.4341 0.0559084 18.6623 0.0112004 17.8849 0.0119004C17.8849 0.0119004 17.8807 0.00382678 17.8805 0H6.11384C6.1136 0.00401345 6.11346 0.00802695 6.11318 0.0119004C5.67198 0.019974 5.23013 0.0166604 4.79117 0.0710754C4.31031 0.130857 3.83406 0.22088 3.3789 0.388978C2.66199 0.654146 2.03911 1.06716 1.50705 1.61854C0.90424 2.24324 0.479327 2.96571 0.253874 3.80699C0.052735 4.5576 0.00896056 5.32314 0.00788719 6.0941"
        fill="black"
      />
      <path
        d="M7.1155 15.2745C7.22574 14.328 7.59827 13.7996 8.29771 13.256C9.30126 12.5224 10.5519 12.9367 10.5519 12.9367V10.4696C10.856 10.462 11.1601 10.481 11.4604 10.5267V13.7008C11.4604 13.7008 10.2098 13.2864 9.20623 14.0201C8.50678 14.5637 8.13425 15.092 8.02401 16.0386C8.02021 16.5518 8.11144 17.2246 8.53719 17.8024C8.43076 17.7454 8.32432 17.6808 8.21408 17.6085C7.28276 16.9547 7.1117 15.9702 7.1155 15.2745ZM16.634 5.90805C15.946 5.12117 15.6837 4.32289 15.5887 3.7641H16.4554C16.4554 3.7641 16.2843 5.23141 17.5425 6.67211L17.5615 6.69112C17.2194 6.47064 16.9077 6.20455 16.634 5.90805ZM20.8117 8.14323V11.2527C20.8117 11.2527 19.7055 11.2071 18.8844 10.9904C17.7402 10.6863 17.0065 10.2188 17.0065 10.2188C17.0065 10.2188 16.4972 9.88423 16.4592 9.86143V16.2857C16.4592 16.643 16.3641 17.5363 16.079 18.2814C15.7065 19.2545 15.1287 19.8969 15.0223 20.0262C15.0223 20.0262 14.319 20.8929 13.0836 21.4745C11.9698 21.9991 10.989 21.9877 10.6963 21.9991C10.6963 21.9991 9.00476 22.0675 7.48043 21.0373C7.14971 20.8092 6.8418 20.5508 6.5605 20.2657L6.56811 20.2733C8.09244 21.3072 9.78403 21.235 9.78403 21.235C10.0767 21.2236 11.0575 21.235 12.1713 20.7104C13.4067 20.1288 14.1099 19.2621 14.1099 19.2621C14.2164 19.1329 14.7942 18.4904 15.1667 17.5173C15.4518 16.7722 15.5468 15.8789 15.5468 15.5216V9.10116C15.5887 9.12397 16.0942 9.45849 16.0942 9.45849C16.0942 9.45849 16.8279 9.92605 17.9721 10.2302C18.7932 10.4468 19.8994 10.4924 19.8994 10.4924V8.0558C20.2795 8.14323 20.6026 8.16603 20.8117 8.14323Z"
        fill="#FE2C55"
      />
      <path
        d="M19.8981 8.05199V10.4886C19.8981 10.4886 18.7919 10.443 17.9708 10.2264C16.8266 9.92225 16.0929 9.45468 16.0929 9.45468C16.0929 9.45468 15.5835 9.12017 15.5455 9.09736V15.5216C15.5455 15.8789 15.4505 16.7722 15.1654 17.5173C14.7929 18.4904 14.2151 19.1329 14.1086 19.2621C14.1086 19.2621 13.4054 20.1288 12.17 20.7104C11.0562 21.235 10.0754 21.2236 9.78273 21.235C9.78273 21.235 8.09114 21.3034 6.5668 20.2733L6.5592 20.2657C6.39955 20.1022 6.24749 19.9311 6.10684 19.7525C5.62027 19.1329 5.32377 18.403 5.24774 18.1939V18.1901C5.1261 17.829 4.87521 16.9547 4.90942 16.1146C4.97025 14.6283 5.47202 13.7122 5.60507 13.4841C5.95479 12.8607 6.41475 12.3019 6.95454 11.8343C7.43351 11.4314 7.97709 11.1083 8.5587 10.884C9.18972 10.6179 9.86635 10.4772 10.5506 10.4658V12.9329C10.5506 12.9329 9.29996 12.5186 8.29641 13.2522C7.59696 13.7958 7.22443 14.3242 7.11419 15.2707C7.11039 15.9664 7.28145 16.9509 8.21658 17.6085C8.32682 17.6808 8.43325 17.7454 8.53969 17.8024C8.70315 18.0229 8.90082 18.2167 9.1289 18.3764C10.045 18.9808 10.8091 19.0226 11.7898 18.6311C12.4437 18.3688 12.934 17.7796 13.1621 17.122C13.3066 16.7114 13.3028 16.3009 13.3028 15.8751V3.7641H15.5835C15.6786 4.32289 15.9371 5.12117 16.6289 5.90805C16.9064 6.20455 17.2181 6.47064 17.5564 6.69112C17.6553 6.80136 18.1684 7.33354 18.8299 7.66426C19.1682 7.83532 19.5293 7.96456 19.8981 8.05199Z"
        fill="white"
      />
      <path
        d="M4.34272 17.4412L4.39974 17.6047C4.39214 17.5857 4.36933 17.5287 4.34272 17.4412Z"
        fill="#25F4EE"
      />
      <path
        d="M8.56162 10.8878C7.97621 11.112 7.43643 11.4351 6.95746 11.8381C6.41387 12.3056 5.95771 12.8682 5.60799 13.4917C5.47494 13.7197 4.97317 14.6321 4.91234 16.1222C4.87813 16.9661 5.13282 17.8366 5.25066 18.1977V18.2015C5.32669 18.4068 5.62319 19.1404 6.10976 19.76C6.25041 19.9387 6.40247 20.1098 6.56212 20.2732C6.04514 19.9159 5.58898 19.4864 5.19744 18.996C4.71468 18.384 4.41817 17.6579 4.34215 17.445V17.4374C4.2205 17.0763 3.96582 16.202 4.00383 15.3581C4.06465 13.8718 4.56642 12.9557 4.69947 12.7276C5.04919 12.1042 5.50535 11.5416 6.04894 11.074C6.52791 10.6711 7.0715 10.348 7.6531 10.1237C8.01803 9.97163 8.39816 9.86139 8.7859 9.78917C9.3713 9.68653 9.96811 9.67893 10.5573 9.76256V10.4696C9.86927 10.481 9.19264 10.6217 8.56162 10.8878Z"
        fill="#25F4EE"
      />
      <path
        d="M15.5934 3.76407H13.3126V15.8751C13.3126 16.3008 13.3126 16.7114 13.172 17.1219C12.9401 17.7758 12.4497 18.3688 11.7997 18.6311C10.819 19.0264 10.0511 18.9808 9.13879 18.3764C8.91071 18.2205 8.71304 18.0266 8.54958 17.8062C9.32885 18.2205 10.0245 18.2129 10.8874 17.867C11.5412 17.6047 12.0316 17.0117 12.2597 16.3579C12.4041 15.9473 12.4003 15.5368 12.4003 15.111V3H15.5516C15.5478 3 15.5136 3.3003 15.5934 3.76407ZM19.9041 7.37913V8.05196C19.5354 7.96453 19.1743 7.83529 18.836 7.66423C18.1783 7.33731 17.6642 6.80136 17.5615 6.69112C17.6794 6.76715 17.7982 6.83554 17.9237 6.90016C18.7295 7.2993 19.5164 7.42094 19.9041 7.37913Z"
        fill="#25F4EE"
      />
    </svg>
  );
};
