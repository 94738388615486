import { useContext, useEffect, useState } from 'react';

import { mutateAuth, mutateImportStatus } from 'src/context/reducer';
import { getResource } from 'src/api';
import { CompetitorJson } from 'src/models/competitors';
import { AuthType, ConnectionType } from 'src/models/connection';
import { PricingPlan } from 'src/models/pricing';
import { Shop, ShopScope } from 'src/models/shop';
import { NextStepsCounter, User } from 'src/models/user';
import { globalContext } from 'src/context';
import { getDatesFromStorage, getStorage } from 'src/utils';

export const useDataLoader = () => {
  const { state, dispatch } = useContext(globalContext);

  const [finished, setFinished] = useState(0);

  const totalTasks = 26;

  useEffect(() => {
    const fetchData = () => {
      getResource<ShopScope>('shop/scope', true)
        .then((scopeResp) => {
          let scope = scopeResp.scope;
          dispatch({ type: 'set-scope', payload: scope });
          setFinished((prev) => prev + 1);

          getResource<User>('user/me', true, true, {}, true)
            .then((user) => {
              dispatch({ type: 'set-user', payload: user });
            })
            .catch(() => {})
            .finally(() => setFinished((prev) => prev + 1));

          getResource<Shop>('shop/me', true)
            .then((shop) => {
              dispatch({ type: 'set-shop', payload: shop });
              dispatch({ type: 'set-modules', payload: [shop.app] });
              dispatch({ type: 'set-app', payload: shop.app });
            })
            .catch(() => {})
            .finally(() => {
              setFinished((prev) => prev + 1);
            });

          if (scope.includes('full')) {
            getResource<NextStepsCounter>('next-steps/count-new-insights', true)
              .then((counter) => {
                dispatch({ type: 'set-next-steps-counter', payload: counter });
              })
              .catch(() => {})
              .finally(() => setFinished((prev) => prev + 1));
          } else {
            setFinished((prev) => prev + 1);
          }

          getResource<CompetitorJson[]>('competitors', true)
            .then((competitors) => {
              dispatch({ type: 'set-competitors', payload: competitors });
            })
            .catch(() => {})
            .finally(() => {
              setFinished((prev) => prev + 1);
            });

          getResource<PricingPlan | null | undefined>('shop/app-plan', true)
            .then((appPlan) => {
              if (getStorage('companyLogin') && process.env.NEXT_PUBLIC_ENV === 'staging') {
                appPlan = 'ultimate';
              } else if (appPlan === undefined) {
                appPlan = null;
              }

              dispatch({ type: 'set-plan', payload: appPlan });
            })
            .catch(() => {})
            .finally(() => {
              setFinished((prev) => prev + 1);
            });

          getResource<string | null>('shop/get-appsumo-plan', true)
            .then((plan) => {
              dispatch({ type: 'set-appsumo-plan', payload: plan });
            })
            .catch(() => {})
            .finally(() => {
              setFinished((prev) => prev + 1);
            });

          for (let type of [
            'facebook',
            'google',
            'tiktok',
            'pinterest',
            'microsoft',
            'klaviyo',
            'ecommerce',
            'business-navigator',
            'competitors',
            'google-analytics',
          ]) {
            mutateImportStatus(type as ConnectionType, dispatch)
              .catch(() => {})
              .finally(() => {
                setFinished((prev) => prev + 1);
              });
          }

          for (let type of [
            'facebook',
            'google',
            'tiktok',
            'pinterest',
            'microsoft',
            'klaviyo',
            'ecommerce',
            'google-analytics',
          ]) {
            mutateAuth(type as AuthType, dispatch)
              .catch(() => {})
              .finally(() => setFinished((prev) => prev + 1));
          }

          getResource<boolean>('shop/standalone-billing', true)
            .then((standaloneBilling) => {
              dispatch({ type: 'set-standalone-billing', payload: standaloneBilling });
            })
            .catch(() => {})
            .finally(() => setFinished((prev) => prev + 1));
        })
        .catch(() => {});
    };

    if (!state.dataLoaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!state.dataLoaded && finished == totalTasks) {
      dispatch({ type: 'set-data-loaded', payload: true });

      // Initialize global dates from session storage
      dispatch({
        type: 'set-dates',
        payload: getDatesFromStorage(`${state.shop?.id}_${state.user?.id}_globalComparePeriod`),
      });
    }
  }, [finished]);

  return state.dataLoaded ? 1 : finished / totalTasks;
};
