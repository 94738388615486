import { motion } from 'framer-motion';
import router from 'next/router';
import React, { useContext } from 'react';
import { Button } from 'src/components/Button';
import {
  FlexVerticalCentered,
  FlexSpaceBetweenCentered,
  FlexRowCentered,
} from 'src/components/utils/Containers';
import { globalContext } from 'src/context';
import { capitalize } from 'src/helpers';
import { DropdownIcon } from 'src/icons/DropdownIcon';
import { LockIcon } from 'src/icons/LockIcon';
import { IRoute, SidebarGroup, SIDEBAR_GROUPS, getAllowedRoutes } from 'src/routes';
import { ThemeContext } from 'styled-components';

import * as S from './style';
import { NewLabel } from 'src/components/Labels/NewLabel';

interface Props {
  activeGroup: SidebarGroup;
  activeTab: string;
  onRouteClick(r: IRoute): void;
  activeDropdowns: string[];
  groupDisabled?: boolean;
}

export const RouteList: React.FC<Props> = ({
  activeDropdowns,
  activeGroup,
  activeTab,
  onRouteClick,
  groupDisabled,
}) => {
  const { state, dispatch } = useContext(globalContext);

  const maxCompetitors = state.shop?.maxCompetitorsNum ?? 3;

  const theme = useContext(ThemeContext);

  const compNotUser = state.competitors.filter((c) => !c.appUser);

  const activeSidebarElement = SIDEBAR_GROUPS.filter((g) => g.key === activeGroup)[0];

  const allAllowedRoutes = getAllowedRoutes(state);

  const isRouteLocked = (r: IRoute): boolean => {
    return r.includedInPlans && state.plan ? r.includedInPlans.includes(state.plan) === false : false;
  };

  const onClick = (r: IRoute) => {
    if (groupDisabled) return;

    if (isRouteLocked(r)) {
      dispatch({ type: 'show-locked-modal', payload: { route: r, group: activeGroup } });
    } else if (r.redirect) {
      document.location.href = r.redirect();
    } else {
      onRouteClick(r);
    }
  };

  const onCompetitorClick = (r: IRoute) => {
    if (state.plan !== 'ultimate') {
      dispatch({
        type: 'show-locked-modal',
        payload: {
          route: { key: 'default', to: 'default', title: 'Competitors', includedInPlans: ['ultimate'] },
          group: activeGroup,
        },
      });
    } else {
      onRouteClick(r);
    }
  };

  const renderRoute = (r: IRoute, i: number) => {
    if (r.shouldHide && r.shouldHide(state)) {
      return;
    }

    return (
      <div>
        <S.RouteContainer
          key={i}
          active={activeTab === activeGroup + r.to}
          onClick={() => onClick(r)}
          disabled={groupDisabled}
          collapsed={activeDropdowns.includes(r.to)}
        >
          {!r.routes ? (
            <FlexVerticalCentered>
              {r.title}
              {r.key === 'next-steps' && state.nextStepsCounter.counter > 0 && (
                <S.NextStepsRouteCounterText>
                  {state.nextStepsCounter.counter >= 10 ? '9+' : state.nextStepsCounter.counter}
                </S.NextStepsRouteCounterText>
              )}
              {isRouteLocked(r) && <LockIcon style={{ marginLeft: 4 }} />}
              {<NewLabel newUntil={r.newUntil} style={{ marginLeft: 4 }} />}
            </FlexVerticalCentered>
          ) : (
            <FlexSpaceBetweenCentered style={{ width: '100%' }}>
              <>
                {r.title}
                {isRouteLocked(r) && <LockIcon style={{ marginLeft: 4 }} />}
              </>

              <DropdownIcon
                collapsed={false || activeDropdowns.includes(r.to)}
                style={{
                  marginRight: 8,
                  color:
                    groupDisabled || isRouteLocked(r)
                      ? theme.colors.grayscale.line
                      : theme.colors.grayscale.titleDark,
                }}
              />
            </FlexSpaceBetweenCentered>
          )}
        </S.RouteContainer>
        {activeDropdowns.includes(r.to) && (
          <S.RouteDropdownContainer
            variants={{
              show: { opacity: 1 },
              hide: { opacity: 0 },
            }}
            transition={{ duration: 1 }}
            animate={activeDropdowns.includes(r.to) ? 'show' : 'hide'}
          >
            {r.routes &&
              r.routes.map((d, j) => (
                <S.DropdownRoute
                  key={j}
                  active={activeTab === activeGroup + d.to}
                  onClick={() => onClick(d)}
                  disabled={groupDisabled}
                >
                  <FlexVerticalCentered>
                    {d.title}
                    {isRouteLocked(d) && <LockIcon style={{ marginLeft: 4 }} />}
                  </FlexVerticalCentered>
                </S.DropdownRoute>
              ))}
          </S.RouteDropdownContainer>
        )}
      </div>
    );
  };

  return (
    <div>
      {activeSidebarElement.routes.map(
        (r, i) =>
          (activeGroup !== 'competitors' || r.to !== 'edit') &&
          allAllowedRoutes.includes(r.key) &&
          renderRoute(r, i)
      )}
      {activeGroup === 'competitors' &&
        (state.scope.includes('full') || state.scope.includes('competitor')) &&
        allAllowedRoutes.includes(activeSidebarElement.key) && (
          <div>
            {compNotUser.map((c, i) => (
              <S.RouteContainer
                key={i}
                active={activeTab === activeGroup + c.id}
                onClick={() =>
                  c.importDone &&
                  maxCompetitors > i &&
                  onCompetitorClick({
                    key: 'competitors-entry',
                    to: c.id.toString(),
                    title: c.competitorName,
                  })
                }
                disabled={!c.importDone || maxCompetitors <= i}
              >
                <FlexVerticalCentered>
                  <FlexRowCentered>
                    {c.competitorLogo ? (
                      <S.CompetitorIcon
                        src={c.competitorLogo}
                        style={{ opacity: !c.importDone || maxCompetitors <= i ? 0.3 : 1 }}
                        alt="Logo"
                      />
                    ) : (
                      <S.DefaultIcon />
                    )}
                    <FlexVerticalCentered style={{ marginLeft: 8 }}>
                      {capitalize(c.competitorName)}
                    </FlexVerticalCentered>
                  </FlexRowCentered>
                  {state.plan !== 'ultimate' && <LockIcon style={{ marginLeft: 4 }} />}
                </FlexVerticalCentered>
              </S.RouteContainer>
            ))}
            {!state.scope.includes('full') &&
            !state.scope.includes('competitors_edit') ? null : compNotUser.length < maxCompetitors &&
              compNotUser.length > 0 ? (
              <FlexRowCentered style={{ marginTop: 16 }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => router.push('/competitors/edit')}
                  locked={state.plan !== 'ultimate'}
                >
                  Add competitor ({compNotUser.length}/{maxCompetitors})
                </Button>
              </FlexRowCentered>
            ) : (
              renderRoute(activeSidebarElement.routes.filter((r) => r.to === 'edit')[0], -1)
            )}
          </div>
        )}
    </div>
  );
};
