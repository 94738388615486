import React, { useReducer } from 'react';

import { GlobalProvider, initialState } from '.';
import { reducer } from './reducer';

export const GlobalContextProvider: React.FC = ({ children }) => {
  // const storedState = process.browser ? getStorage('userCtx') : null;

  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   setStorage('userCtx', JSON.stringify(state));
  // }, [state]);

  return <GlobalProvider value={{ state: state, dispatch }}>{children}</GlobalProvider>;
};
