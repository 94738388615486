import { motion } from 'framer-motion';
import { FlexRowCentered } from 'src/components/utils/Containers';
import styled from 'styled-components';

export const SectionsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkBlue2};
  height: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 72px;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 46px;
`;

export const SectionIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
  height: 100%;
`;

export const NavigationContainer = styled(motion.div)<{ trianglePos: string; expanded: boolean }>`
  background-color: ${(props) => props.theme.colors.grayscale.offWhite};
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.expanded ? '0 8px' : '0px')};
  padding-top: 104px;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.16);
  height: 100%;

  position: relative;

  &::after {
    content: '';
    display: ${(props) => (props.expanded ? '' : 'none')};
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${(props) => props.theme.colors.grayscale.offWhite};
    top: ${(props) => props.trianglePos};
    left: -10px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.grayscale.gray2};
  font-size: 14px;
  font-weight: 700;
  margin-left: 24px;
  margin-right: 32px;
  align-self: flex-start;
  height: 42px;
  margin-bottom: 38px;
`;

export const SwitchShopContainer = styled(FlexRowCentered)`
  position: absolute;
  bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LebesgueLogo = styled(motion.img)`
  height: 32px;
  width: 32px;
  flexshrink: 0;
`;
