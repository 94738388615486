import React from 'react';

export const CompetitorsIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.97852e-07C17.1046 2.46134e-07 18 0.895431 18 2V6.52632C18 7.63089 17.1046 8.52632 16 8.52632H11.4737C10.3691 8.52632 9.47368 7.63089 9.47368 6.52632V2C9.47368 0.89543 10.3691 -4.82823e-08 11.4737 0L16 1.97852e-07Z"
        fill="#00B0E8"
      />
      <path
        d="M2.85276e-07 2C2.85276e-07 0.89543 0.895431 2.85274e-07 2 2.85274e-07H6.52632C7.63089 2.85274e-07 8.52632 0.895431 8.52632 2V6.52632C8.52632 7.63089 7.63089 8.52632 6.52632 8.52632H2C0.89543 8.52632 2.85276e-07 7.63089 2.85276e-07 6.52632V2Z"
        fill="#BCE4FC"
      />
      <path
        d="M18 16C18 17.1046 17.1046 18 16 18H11.4737C10.3691 18 9.47368 17.1046 9.47368 16V11.4737C9.47368 10.3691 10.3691 9.47369 11.4737 9.47369L16 9.47368C17.1046 9.47368 18 10.3691 18 11.4737V16Z"
        fill="#3B90BD"
      />
      <path
        d="M2 18C0.89543 18 2.4613e-07 17.1046 1.97848e-07 16L0 11.4737C-4.82823e-08 10.3691 0.895431 9.47368 2 9.47368H6.52632C7.63089 9.47368 8.52632 10.3691 8.52632 11.4737V16C8.52632 17.1046 7.63089 18 6.52632 18H2Z"
        fill="#EBF5FF"
      />
    </svg>
  );
};
