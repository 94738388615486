export function capitalize(s?: string): string {
  if (s === undefined) return '';
  return s
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
}

export function validateEmail(email: string): boolean {
  const pattern = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return pattern.test(email);
}

export const downloadFile = (data: string, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const format = (str: string, ...args: string[]): string => {
  let i = 0;
  let len = args.length;

  const matches = str.match(/{}/g);

  if (!matches || matches.length !== len) {
    return '';
  }

  while (i < len) {
    str = str.replace(/{}/, args[i]);
    i++;
  }

  return str;
};
