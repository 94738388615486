import { CompetitorsIcon } from './icons/CompetitorsIcon';
import { CostsIcon } from './icons/CostsIcon';
import { BusinessNavigatorIcon } from './icons/BusinessNavigatorIcon';
import { EcommerceIcon } from './icons/EcommerceIcon';
import { FacebookSidebarIcon } from './icons/FacebookSidebarIcon';
import { GoogleSidebarIcon } from './icons/GoogleSidebarIcon';
import { RobotSidebarIcon } from './icons/RobotSidebarIcon';
import { SettingsIcon } from './icons/SettingsIcon';
import { TikTokNoBgIcon } from './icons/TikTokNoBgIcon';
import { PricingPlan } from './models/pricing';
import { GlobalContextType } from 'src/context/model';
import { MODULE_ROUTES, ROUTES_PRIORITY_ORDER, SCOPE_ROUTES, ScopeType } from './appConfig';
import { AuthType, ConnectionType } from './models/connection';
import { getStorage } from './utils';

export type SidebarGroup =
  | 'ecommerce'
  | 'facebook'
  | 'google'
  | 'tiktok'
  | 'robot'
  | 'competitors'
  | 'business-navigator'
  | 'costs'
  | 'settings';

export interface IRoute {
  title: string;
  routes?: IRoute[];
  to: string;
  key: string;
  locked?: boolean;
  includedInPlans?: PricingPlan[];
  newUntil?: string;
  redirect?: () => string;
  shouldHide?: (state: GlobalContextType) => boolean;
}

export interface IRouteGroup {
  title: string;
  key: SidebarGroup;
  section: 'upper' | 'lower';
  Icon: React.FC;
  routes: IRoute[];
  newUntil?: Date;
  locked?: boolean;
  includedInPlans?: PricingPlan[];
}

export const SIDEBAR_GROUPS: IRouteGroup[] = [
  {
    title: 'Ecommerce',
    key: 'ecommerce',
    Icon: EcommerceIcon,
    section: 'upper',
    newUntil: new Date('2024-8-15'),
    routes: [
      {
        key: 'business',
        title: 'Business Report',
        to: 'business',
      },
      {
        key: 'product-intelligence',
        title: 'Product Intelligence',
        to: 'product-intelligence',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
        newUntil: '2024-7-20',
      },
      {
        key: 'stock-inventory',
        title: 'Stock Inventory',
        to: 'stock-inventory',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
        newUntil: '2024-8-15',
      },
      {
        key: 'ltv-analysis',
        title: 'LTV Analysis',
        to: 'ltv-analysis',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'ltv-breakdowns',
        title: 'LTV Breakdowns',
        to: 'ltv-breakdowns',
        locked: true,
        routes: [
          {
            key: 'ltv-product',
            title: 'Product',
            to: 'product',
            includedInPlans: ['advanced', 'ultimate'],
          },
          {
            key: 'ltv-channel',
            title: 'Marketing Channel',
            to: 'channel',
            includedInPlans: ['advanced', 'ultimate'],
          },
          {
            key: 'ltv-discount',
            title: 'Discount',
            to: 'discount',
            includedInPlans: ['advanced', 'ultimate'],
          },
          {
            key: 'ltv-country',
            title: 'Country',
            to: 'country',
            includedInPlans: ['advanced', 'ultimate'],
          },
          {
            key: 'ltv-state',
            title: 'State',
            to: 'state',
            includedInPlans: ['advanced', 'ultimate'],
          },
        ],
      },
    ],
  },
  {
    title: 'Business Navigator',
    key: 'business-navigator',
    Icon: BusinessNavigatorIcon,
    section: 'upper',
    routes: [
      {
        key: 'next-steps',
        title: 'Next Steps',
        to: 'next-steps',
        locked: true,
      },
      {
        key: 'creative-tab',
        title: 'Creative Strategy',
        to: 'creative-tab',
        locked: true,
        includedInPlans: ['ultimate'],
      },
    ],
  },
  {
    title: 'Facebook Advertising',
    key: 'facebook',
    Icon: FacebookSidebarIcon,
    section: 'upper',
    routes: [
      {
        key: 'fb-analytics',
        title: 'Analytics',
        to: 'analytics',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'fb-market',
        title: 'Market Intelligence',
        to: 'market',
        locked: true,
        routes: [
          {
            key: 'fb-benchmarks',
            title: 'Benchmarks',
            to: 'benchmarks',
            includedInPlans: ['advanced', 'ultimate'],
          },
          {
            key: 'fb-forecast',
            title: 'Forecast',
            to: 'forecast',
            includedInPlans: ['ultimate'],
          },
        ],
      },
      {
        key: 'fb-audit',
        title: 'Advertising Audit',
        to: 'account-quality',
      },
    ],
  },

  {
    title: 'Google Advertising',
    key: 'google',
    Icon: GoogleSidebarIcon,
    section: 'upper',
    routes: [
      {
        key: 'ga-analytics',
        title: 'Analytics',
        to: 'analytics',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'ga-market',
        title: 'Market Intelligence',
        to: 'market-intelligence',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'ga-audit',
        title: 'Advertising Audit',
        to: 'account-quality',
      },
    ],
  },

  {
    title: 'TikTok Advertising',
    key: 'tiktok',
    Icon: TikTokNoBgIcon,
    section: 'upper',
    routes: [
      {
        key: 'tt-analytics',
        title: 'Analytics',
        to: 'analytics',
        locked: true,
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'tt-audit',
        title: 'Advertising Audit',
        to: 'account-quality',
      },
    ],
  },

  {
    title: 'AI Creator',
    key: 'robot',
    Icon: RobotSidebarIcon,
    section: 'upper',
    routes: [
      {
        key: 'ad-creator',
        title: 'Ad Creator',
        to: 'ad-creator',
        locked: true,
        includedInPlans: ['ultimate'],
      },
      {
        key: 'copywriter',
        title: 'AI Copywriter',
        to: 'copywriter',
        locked: true,
        routes: [
          {
            key: 'copywriter-product',
            title: 'Product',
            to: 'copywriter-product',
            includedInPlans: ['ultimate'],
          },
          {
            key: 'copywriter-adaptive',
            title: 'Adaptive',
            to: 'copywriter-adaptive',
            includedInPlans: ['ultimate'],
          },
          {
            key: 'copywriter-performance-based',
            title: 'Performance-Based',
            to: 'copywriter-performance-based',
            includedInPlans: ['ultimate'],
          },
        ],
      },
    ],
  },

  {
    title: 'Competitors Intelligence',
    key: 'competitors',
    Icon: CompetitorsIcon,
    section: 'upper',
    includedInPlans: ['ultimate'],
    routes: [
      {
        key: 'competitors-landscape',
        title: 'Competitors Landscape',
        to: 'landscape',
        locked: true,
        includedInPlans: ['ultimate'],
      },
      {
        key: 'competitors-edit',
        title: 'Edit / Add New',
        to: 'edit',
        includedInPlans: ['ultimate'],
      },
    ],
    locked: true,
  },
  {
    title: 'Cost and Expenses',
    key: 'costs',
    Icon: CostsIcon,
    section: 'lower',
    includedInPlans: ['advanced', 'ultimate'],
    routes: [
      {
        key: 'custom-costs',
        title: 'Custom Costs',
        to: 'custom',
        includedInPlans: ['advanced', 'ultimate'],
      },
      {
        key: 'product-costs',
        title: 'Product Costs',
        to: 'product',
        includedInPlans: ['advanced', 'ultimate'],
      },
    ],
  },

  {
    title: 'Settings',
    key: 'settings',
    section: 'lower',
    Icon: SettingsIcon,
    routes: [
      {
        key: 'settings-shop',
        title: 'Shop Settings',
        to: 'shop',
      },
      { key: 'settings-integrations', title: 'Integrations', to: 'integrations' },
      {
        key: 'settings-pricing',
        title: 'Pricing',
        to: 'pricing',
      },
      {
        key: 'settings-billing-portal',
        title: 'Billing Portal',
        to: 'billing-portal',
        redirect: () =>
          `${process.env.NEXT_PUBLIC_BACKEND_HOST}/api/billing/portal?token=${JSON.parse(
            getStorage('token') || '{}'
          )}`,
        shouldHide: (state: GlobalContextType) => !state.standaloneBilling,
      },
      {
        key: 'settings-user-administration',
        title: 'User Administration',
        to: 'user-administration',
      },
    ],
  },
];

const getRoutesRecursively = (prefix: string, curr: IRouteGroup | IRoute) => {
  const newPrefix = 'to' in curr ? `${prefix}/${curr.to}` : `${prefix}/${curr.key}`;

  let allRoutes = [
    {
      key: curr.key,
      route: newPrefix,
    },
  ];

  if (curr.routes) {
    for (let r of curr.routes) {
      allRoutes = [...allRoutes, ...getRoutesRecursively(newPrefix, r)];
    }
  }

  return allRoutes;
};

export const ALL_ROUTES = SIDEBAR_GROUPS.reduce((prev: { key: string; route: string }[], curr) => {
  let res: { key: string; route: string }[] = [...prev, ...getRoutesRecursively('', curr)];
  return res;
}, []);

export const getAllowedRoutes = (state: GlobalContextType): string[] => {
  let allowedRoutes = ALL_ROUTES.map((r) => r.key);
  const allScopeRoutes = state.scope.map((s) => SCOPE_ROUTES[s as ScopeType] || []).flat(1);
  const allModulesRoutes = state.modules.map((m) => MODULE_ROUTES[m] || []).flat(1);

  if (!state.modules.includes('lebesgue')) {
    allowedRoutes = allowedRoutes.filter((r) => allModulesRoutes.includes(r));
  }
  if (state.scope.includes('full') && !state.scope.includes('admin')) {
    allowedRoutes = allowedRoutes.filter(
      (r) =>
        ['settings-billing-portal', 'settings-user-administration', 'settings-pricing'].includes(r) === false
    );
  } else if (!state.scope.includes('full')) {
    allowedRoutes = allowedRoutes.filter((r) => allScopeRoutes.includes(r));
  }

  if (
    !allowedRoutes.includes('settings-integrations') &&
    Object.entries(state.adsAccountAuth).some(
      (keyValue) => keyValue[0] !== 'ecommerce' && keyValue[1].userConnected
    )
  ) {
    allowedRoutes = [...allowedRoutes, 'settings-integrations'];
    if (!allowedRoutes.includes('settings')) {
      allowedRoutes = [...allowedRoutes, 'settings'];
    }
  }

  return allowedRoutes;
};

export const isRouteIncludedInPlan = (route: IRoute, group: IRouteGroup, state: GlobalContextType) => {
  if (state.plan && route.includedInPlans && !route.includedInPlans.includes(state.plan)) return false;
  if (group.key in state.adsAccountAuth && !state.adsAccountAuth[group.key as AuthType].connected)
    return false;
  if (
    group.key !== 'competitors' &&
    group.key in state.status &&
    !state.status[group.key as ConnectionType].completed
  )
    return false;
  if (group.key === 'competitors' && state.plan !== 'ultimate') return false;

  return true;
};

export const isSourceDisconnectAllowed = (state: GlobalContextType, source: string) => {
  if (state.scope.includes('full')) return true;
  if (source === 'competitor') return state.scope.includes('competitors_edit');

  return (
    (state.scope.includes('settings_integrations') &&
      (state.scope.includes(source) ||
        ['pinterest', 'microsoft', 'klaviyo', 'ecommerce'].includes(source))) ||
    state.adsAccountAuth[source as AuthType].userConnected
  );
};

export const isSourceConnectAllowed = (state: GlobalContextType, source: string) => {
  if (state.scope.includes('full')) return true;
  if (source === 'competitor') return state.scope.includes('competitors_edit');

  return (
    state.scope.includes('settings_integrations') &&
    (state.scope.includes(source) || ['pinterest', 'microsoft', 'klaviyo', 'ecommerce'].includes(source))
  );
};

export const getDefaultRedirectRoute = (state: GlobalContextType): string => {
  const allAllowedRoutes = getAllowedRoutes(state);

  let redirectRouteKey = '';
  for (let route of ROUTES_PRIORITY_ORDER) {
    if (!allAllowedRoutes.includes(route)) continue;
    redirectRouteKey = route;

    for (let g of SIDEBAR_GROUPS) {
      for (let r of g.routes) {
        if (!isRouteIncludedInPlan(r, g, state)) continue;
        if (r.key === redirectRouteKey) {
          return `/${g.key}/${r.to}`;
        }
      }
    }
  }

  return '/ecommerce/business';
};

export const getRedirectRouteKey = (redirect: string) => {
  let redirect_path = redirect.split('#')[0];
  let route = ALL_ROUTES.find((r) => r.route === redirect_path);

  if (route === undefined) {
    console.error(`Unknown route: ${redirect}`);
    return '';
  }

  return route.key;
};
