import styled from 'styled-components';

export const FullHWCenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const PageContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  position: relative;
`;
