import styled from 'styled-components';
import { SpinnerIos } from '@styled-icons/fluentui-system-filled/SpinnerIos';

import { rotate } from 'styles/animations';
import { ButtonSize, ButtonStyleProps, ButtonType } from './interface';
import { flexRowCentered } from '../utils/helpers';

const ButtonBase = styled.button<ButtonStyleProps>`
  height: ${(props) => (props.size === 'big' ? '40px' : props.size === 'medium' ? '32px' : '24px')};
  padding-left: ${(props) =>
    props.hasIcon
      ? props.size === 'big'
        ? '12px'
        : props.size === 'medium'
        ? '8px'
        : '4px'
      : props.size === 'big'
      ? '40px'
      : '20px'};
  padding-right: ${(props) =>
    props.hasIcon
      ? props.size === 'big'
        ? '28px'
        : props.size === 'medium'
        ? '16px'
        : '12px'
      : props.size === 'big'
      ? '40px'
      : '20px'};
  border-radius: 40px;
  font-size: ${(props) => (props.size === 'big' ? '12px' : '10px')};
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
  letter-spacing: ${(props) => (props.size === 'big' ? '0.75px' : '0.45px')};
  font-family: 'Poppins', sans-serif;
`;

const Primary = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.primary.darkBlue1};
  color: white;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.darkBlue2};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary.darkBlue2};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.grayscale.line};
    color: ${(props) => props.theme.colors.grayscale.elementBg};
    cursor: not-allowed;
  }
`;

const Secondary = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.darkBlue1};
  border-color: ${(props) => props.theme.colors.primary.darkBlue1};
  background-color: white;
  border-width: ${(props) => (props.size === 'small' ? '1px' : '2px')};
  border-style: solid;
  
  &:hover {
    background-color: ${(props) => props.theme.colors.grayscale.elementBg};
    border-color${(props) => props.theme.colors.primary.darkBlue2};
    color: ${(props) => props.theme.colors.primary.darkBlue2};
  }

  &:focus-visible {
    outline-color: ${(props) => props.theme.colors.grayscale.elementBg};
    outline-width: ${(props) => (props.size === 'big' ? '8px' : props.size === 'medium' ? '6px' : '4px')};
    outline-style: solid;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.grayscale.elementBg};
    border-color${(props) => props.theme.colors.primary.darkBlue2};
    color: ${(props) => props.theme.colors.primary.darkBlue2};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.grayscale.line};
    border-color: ${(props) => props.theme.colors.grayscale.line};
    cursor: not-allowed;
  }
`;

const PrimaryAlert = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.alert.accentRed};
  color: white;

  &:hover {
    background-color: ${(props) => props.theme.colors.alert.dark};
  }

  &:focus-visible {
    outline-color: ${(props) => props.theme.colors.alert.accentRed};
    outline-width: ${(props) => (props.size === 'big' ? '8px' : props.size === 'medium' ? '4px' : '2px')};
    outline-style: solid;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.alert.dark};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.alert.light2};
    color: ${(props) => props.theme.colors.grayscale.elementBg};
    cursor: not-allowed;
  }
`;

const PrimaryNotification = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.notifications.default};
  color: ${(props) => props.theme.colors.primary.darkBlue2};

  &:hover {
    background-color: ${(props) => props.theme.colors.notifications.light2};
  }

  &:focus-visible {
    outline-color: ${(props) => props.theme.colors.notifications.border};
    outline-width: ${(props) => (props.size === 'big' ? '8px' : props.size === 'medium' ? '4px' : '2px')};
    outline-style: solid;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.notifications.border};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.notifications.light2};
    color: ${(props) => props.theme.colors.primary.darkBlue2};
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(Primary)`
  ${flexRowCentered};
`;

export const SecondaryButton = styled(Secondary)`
  ${flexRowCentered};
`;

export const AlertButton = styled(PrimaryAlert)`
  ${flexRowCentered};
`;

export const NotificationButton = styled(PrimaryNotification)`
  ${flexRowCentered};
`;

export const IconButton = styled(Primary)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonLoader = styled(SpinnerIos)<{
  type: ButtonType;
  size: ButtonSize;
}>`
  height: ${(props) => (props.size === 'big' ? '20px' : props.size === 'medium' ? '16px' : '12px')};
  width: ${(props) => (props.size === 'big' ? '20px' : props.size === 'medium' ? '16px' : '12px')};
  color: ${(props) =>
    props.type === 'primary' || props.type === 'icon' ? 'white' : props.theme.colors.primary.darkBlue1};

  animation: ${rotate} 2s linear infinite;
`;
