import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const blink = (
  color1: string,
  color2: string,
  color3: string,
  color4: string,
  color5: string
) => keyframes`
  0% {
    background-color: ${color1}; 
  } 
  25% {
    background-color: ${color2};
  }
  50% {
    background-color: ${color3};
  }
  75% {
    background-color: ${color4};
  }
  100% {
    background-color: ${color5};
  }
`;

export const bounceAnimation = keyframes`
  to {
    opacity: 1;
    transform: translateY(-16px);
  }
`;
