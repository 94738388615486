import React from 'react';

export const CloseIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6 6L18.7739 18.7739"
        stroke={style?.stroke || '#6E7191'}
        strokeWidth="1.49997"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 18.7742L18.7739 6.00023"
        stroke={style?.stroke || '#6E7191'}
        strokeWidth="1.49997"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
