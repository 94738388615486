import { SidebarGroup } from './routes';

export const TUTORIALS_CONFIG: {
  [k in SidebarGroup]: { [k: string]: { title: string; text: string; video: string; features: string[] } };
} = {
  ecommerce: {
    'ltv-analysis': {
      text: 'Gain a deeper understanding of long-term customer behavior with extensive and customizable cohort analysis.',
      title: 'LTV Analysis',
      video: 'https://www.youtube.com/embed/iri80p6wWcM',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    product: {
      text: 'Compare the lifetime value of different products and uncover which products drive customer loyalty and repeat purchases.',
      title: 'Product Breakdown',
      video: 'https://www.youtube.com/embed/9KysPhG8XZM',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    channel: {
      text: 'Analyze marketing channels to determine which ones effectively attract high-value customers.',
      title: 'Marketing Channel Breakdown',
      video: 'https://www.youtube.com/embed/svjc-fv_5mk',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    country: {
      text: 'Compare customer behavior across countries and adapt marketing efforts for maximum impact.',
      title: 'Country Breakdown',
      video: 'https://www.youtube.com/embed/MKO7cE1dXZY',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    state: {
      text: 'Evaluate customer behavior across US states and tailor marketing strategies for optimal results.',
      title: 'State Breakdown',
      video: 'https://www.youtube.com/embed/MKO7cE1dXZY',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    discount: {
      text: 'Determine which discounts foster customer loyalty and lead to greater long-term value.',
      title: 'Discount Breakdown',
      video: 'https://www.youtube.com/embed/eL5TMe2HzII',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    'product-intelligence': {
      text: 'Track the sales performance of your products to analyze trends, seasonal variations, and growth patterns.',
      title: 'Product Intelligence',
      video: 'https://www.youtube.com/embed/9KysPhG8XZM',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
    'stock-inventory': {
      text: 'Improve your inventory management, analyze stock levels, and effortlessly track product categories.',
      title: 'Stock Inventory',
      video: 'https://www.youtube.com/embed/9KysPhG8XZM',
      features: ['Advertising Analytics', 'Benchmarks', '24/7 support'],
    },
  },
  'business-navigator': {
    'next-steps': {
      text: '',
      title: 'Next Steps',
      video: 'https://youtube.com/embed/jEM7nGAP-JU',
      features: ['Next Steps'],
    },
    'creative-tab': {
      text: 'Optimize your Facebook budget split between multiple ad categories and analyze all your creatives and their metrics in one place to see which perform best.',
      title: 'Creative Strategy',
      video: 'https://www.youtube.com/embed/So7Cr69Km8A?si=jDgz0PT6e3T_P6D2',
      features: ['Next Steps', 'Competitor Intelligence', 'Ad Creator'],
    },
  },
  facebook: {
    benchmarks: {
      title: 'Market Benchmarks',
      text: 'Compare your advertising data to real-time industry benchmarks and indentify your weakest metrics.',
      video: 'https://youtube.com/embed/IXz3m2GdwKA',
      features: ['LTV', 'Advertising Analytics', '24/7 support'],
    },
    forecast: {
      title: 'CPM Forecast',
      text: 'Forecast your advertising price on Facebook Ads, and compare your forecast with the whole market. ',
      video: 'https://youtube.com/embed/poOdRjrszt0',
      features: ['AI copywriter', 'Competitor Intelligence', '24/7 support'],
    },
    analytics: {
      title: 'Advertising Analytics',
      text: 'Analyze your ads performance, understand how performance changes over time, and compare results to market benchmarks.',
      video: 'https://youtube.com/embed/Y7PZnD8FJ8k',
      features: ['LTV', 'Benchmarks', '24/7 support'],
    },
  },
  google: {
    'market-intelligence': {
      text: 'Compare your advertising data to real-time industry benchmarks and indentify your weakest metrics.',
      video: 'https://youtube.com/embed/yrZHP4QNJOQ',
      title: 'Market Benchmarks',
      features: ['LTV', 'Advertising Analytics', '24/7 support'],
    },
    analytics: {
      text: 'Analyze your ads performance, understand how performance changes over time, and compare results to market benchmarks.',
      video: 'https://youtube.com/embed/NKKfYkZFIug',
      title: 'Advertising Analytics',
      features: ['LTV', 'Benchmarks', '24/7 support'],
    },
  },
  tiktok: {
    analytics: {
      text: 'Analyze your ads performance, understand how performance changes over time, and compare results to market benchmarks.',
      video: 'https://youtube.com/embed/NKKfYkZFIug',
      title: 'Advertising Analytics',
      features: ['LTV', 'Benchmarks', '24/7 support'],
    },
  },
  robot: {
    'ad-creator': {
      title: 'Ad Creator',
      text: 'Create copies and preview Facebook ads',
      video: 'https://www.youtube.com/embed/WnraVUly5bk',
      features: ['Competitor Intelligence', 'CPM Forecast', '24/7 support'],
    },
    'copywriter-product': {
      title: 'Product Copywriter',
      text: 'Effortlessly generate captivating and compelling product copy with just one click of a button.',
      video: 'https://www.youtube.com/embed/WnraVUly5bk',
      features: ['Competitor Intelligence', 'CPM Forecast', '24/7 support'],
    },
    'copywriter-adaptive': {
      title: 'Adaptive Copywriter',
      text: 'Generate flexible, AI-powered ad copy that adapts to the communication on your website.',
      video: 'https://www.youtube.com/embed/WnraVUly5bk',
      features: ['Competitor Intelligence', 'CPM Forecast', '24/7 support'],
    },
    'copywriter-performance-based': {
      title: 'Performance-Based Copywriter',
      text: 'Generate AI-powered performance-based ad copy that matches the best-performing elements of your past ads.',
      video: 'https://www.youtube.com/embed/WnraVUly5bk',
      features: ['Competitor Intelligence', 'CPM Forecast', '24/7 support'],
    },
  },
  competitors: {
    landscape: {
      title: 'Competitors Intelligence',
      text: "Enhance your marketing strategies by monitoring competitors' growth and tactics, making informed decisions and consistently outperforming the competition.",
      video: 'https://www.youtube.com/embed/3cHwe8Xj5_s',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
    default: {
      title: 'Competitor Preview',
      text: "Enhance your marketing strategies by monitoring competitors' growth and tactics, making informed decisions and consistently outperforming the competition.",
      video: 'https://www.youtube.com/embed/3cHwe8Xj5_s',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
    edit: {
      title: 'Competitors Edit',
      text: "Enhance your marketing strategies by monitoring competitors' growth and tactics, making informed decisions and consistently outperforming the competition.",
      video: 'https://www.youtube.com/embed/3cHwe8Xj5_s',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
  },
  costs: {
    custom: {
      title: 'Custom Costs',
      text: 'Add your fixed or percentage-based costs for accurate business reporting and better decision-making. Categorize them to help Lebesgue optimize your marketing efforts effectively. Set a timeframe for cost distribution, ranging from one day to one month, to analyze time-based impacts, leading to better insights and informed marketing decisions.',
      video: 'https://www.youtube.com/embed/x3KSVS0-1NY',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
    product: {
      title: 'Product Costs',
      text: 'Take full control of your COGS within Lebesgue by utilizing the Lebesgue product cost field. Easily manage and monitor your product costs using this feature. Stay informed and track the impact of your cost management decision.',
      video: 'https://www.youtube.com/embed/rCN8V4kun7U',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
  },
  settings: {
    'billing-portal': {
      title: 'Billing Portal',
      text: "Enhance your marketing strategies by monitoring competitors' growth and tactics, making informed decisions and consistently outperforming the competition.",
      video: 'https://www.youtube.com/embed/3cHwe8Xj5_s',
      features: ['AI copywriter', 'CPM Forecast', '24/7 support'],
    },
  },
};
