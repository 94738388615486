import React, { useContext } from 'react';

import { Sidebar } from 'src/containers/Sidebar';
import { MainContainer } from './MainContainer';

import * as S from './style';
import { globalContext } from 'src/context';

export const Layout: React.FC = ({ children }) => {
  const { state } = useContext(globalContext);

  return (
    <>
      <S.Wrapper>
        <S.SidebarWrapper>
          <Sidebar />
        </S.SidebarWrapper>
        <MainContainer>{children}</MainContainer>
      </S.Wrapper>
    </>
  );
};
