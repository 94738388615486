import React from 'react';

export const EcommerceIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.00002C0 5.89545 0.895431 5.00002 2 5.00002H3.5C4.60457 5.00002 5.5 5.89545 5.5 7.00002V16C5.5 17.1046 4.60457 18 3.5 18H2C0.895431 18 0 17.1046 0 16V7.00002Z"
        fill="#BCE4FC"
      />
      <path
        d="M8.25 2C8.25 0.89543 9.14543 0 10.25 0H11.75C12.8546 0 13.75 0.895431 13.75 2V16C13.75 17.1046 12.8546 18 11.75 18H10.25C9.14543 18 8.25 17.1046 8.25 16V2Z"
        fill="#00B0E8"
      />
      <path
        d="M16.5 12C16.5 10.8954 17.3954 10 18.5 10H20C21.1046 10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H18.5C17.3954 18 16.5 17.1046 16.5 16V12Z"
        fill="white"
      />
    </svg>
  );
};
