import { createContext, Dispatch } from 'react';
import { GlobalContextType } from './model';
import { Action } from './reducer';

export const initialState: GlobalContextType = {
  theme: 'dark',
  sidebarExpanded: false,
  status: {
    ecommerce: { connected: true, total: 0, finished: false, completed: 0, finished_at: null },

    competitors: { connected: false, total: 1, finished: false, completed: 0, finished_at: null },
    'business-navigator': { connected: false, total: 1, finished: false, completed: 0, finished_at: null },

    facebook: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    google: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    tiktok: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    pinterest: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    microsoft: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    klaviyo: { connected: false, total: 0, finished: false, completed: 0, finished_at: null, accounts: [] },
    'google-analytics': {
      connected: false,
      total: 0,
      finished: false,
      completed: 0,
      finished_at: null,
      accounts: [],
    },
  },
  competitors: [],
  adsAccountAuth: {
    ecommerce: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    facebook: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    google: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    tiktok: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    pinterest: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    microsoft: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    klaviyo: { connected: false, needsRefresh: false, hasFullScope: false, href: '', userConnected: false },
    'google-analytics': {
      connected: false,
      needsRefresh: false,
      hasFullScope: false,
      href: '',
      userConnected: false,
    },
  },
  app: 'lebesgue',
  scope: [],
  modules: ['lebesgue'],
  plan: null,
  appsumoPlan: null,
  showLockedModal: null,
  showIntroModal: false,
  standaloneBilling: false,
  locked: false,
  refreshImportProgress: 0,
  nextStepsCounter: { counter: 0 },
  refreshAuth: 0,
  darkBg: false,
  dataLoaded: false,
  dates: {
    startDate: undefined,
    endDate: undefined,
    endDateCompare: undefined,
    startDateCompare: undefined,
    compareOption: 'prev_period',
  },
};

export const globalContext = createContext<{
  state: GlobalContextType;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => {} });
export const GlobalProvider = globalContext.Provider;
