import React from 'react';
import { LockIcon } from 'src/icons/LockIcon';
import { ButtonProps, ButtonStyleProps, ButtonType } from './interface';

import * as S from './style';

export const Buttons: {
  [key in ButtonType]: React.FC<
    | ButtonStyleProps
    | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  >;
} = {
  primary: S.PrimaryButton,
  secondary: S.SecondaryButton,
  icon: S.IconButton,
  alert: S.AlertButton,
  notification: S.NotificationButton,
};

export const Button: React.FC<ButtonProps> = ({
  type,
  size,
  loading = false,
  disabled = false,
  onClick,
  style,
  Icon,
  children,
  locked,
}) => {
  const Button = Buttons[type];

  return (
    <Button
      disabled={disabled || locked}
      size={size}
      onClick={onClick}
      style={style}
      hasIcon={Icon && !loading}
    >
      {Icon && !loading && (
        <Icon
          style={{
            marginRight: size === 'big' ? 16 : size === 'medium' ? 12 : 8,
            height: 16,
            width: 16,
          }}
          disabled={disabled || locked}
        />
      )}
      {loading ? <S.ButtonLoader type={type} size={size} /> : children}
      {locked && <LockIcon style={{ marginLeft: 8 }} />}
    </Button>
  );
};
