import React from 'react';

export const FacebookSidebarIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <svg
      width="11"
      height="22"
      viewBox="0 0 11 22"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2797 12.538L10.8492 8.50375H7.28303V5.88582C7.28303 4.78253 7.78122 3.70691 9.37861 3.70691H11V0.272961C11 0.272961 9.52872 0 8.12165 0C5.18446 0 3.26521 1.93172 3.26521 5.42866V8.50375H0V12.538H3.26521V22H7.28303V12.538H10.2797Z"
        fill="white"
      />
    </svg>
  );
};
