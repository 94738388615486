import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
  colors: {
    grayscale: {
      titleDark: '#14142b',
      body: '#4e4b66',
      gray1: '#6e7191',
      gray2: '#a0a3bd',
      line: '#d9dbe9',
      inputBg: '#edeef5',
      elementBg: '#f7f7fc',
      offWhite: '#fcfcfc',
      gridLine: '#dfe0eb',
    },

    primary: {
      darkBlue1: '#283366',
      darkBlue2: '#151b37',
      blue: '#3b90bd',
      accentBlue: '#00b0e8',
      lightBlue1: '#bce4fc',
      lightBlue2: '#d6f0ff',
      lightBlue3: '#ebf5ff',
      graphPurple: '#a700c3',
      graphGreen: '#12857e',
      graphLightGreen: '#a2d253',
      graphTurquoise: '#5addef',
      competitorPurple: '#6f3ea2',
    },

    alert: {
      accentRed: '#ed2e7e',
      dark: '#c30052',
      light1: '#ffb0d1',
      light2: '#ffdfed',
      light3: '#fff2f8',
    },

    notifications: {
      default: '#f1b43c',
      light: '#fff6c7',
      border: '#946200',
      light2: '#ffd789',
      ultraLight: '#fffdef',
    },

    gradients: {
      blueRed: 'linear-gradient(to right, #bce4fc, #ffdfed)',
      blueYellow: 'linear-gradient(to right, #bce4fc, #FFF6C7)',
      bluePrimary: 'linear-gradient(to right, #5a6bba, #010633)',
      redAccent: 'linear-gradient(to right, #ff84b7, #ff84b7)',
    },
  },
};
