import useSWR from 'swr';
import queryString from 'query-string';

import { JsonData } from 'src/models/utils';
import { API_BASE_URL, BASE_URL, R_BASE_URL } from './utils';
import { getStorage } from 'src/utils';
import { getTokenData, resolveTokens } from 'src/services/TokenService';

export const headers: HeadersInit = {
  'Content-Type': 'application/json',
};

export const deleteResource = async <R extends JsonData | JsonData[] | boolean>(
  url: string,
  auth: boolean = true,
  asUser?: boolean
): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: getHeaders(auth, asUser),
    method: 'DELETE',
  });

  return checkResponse(response);
};

/**
 * Generic function for updating resources on server.
 *
 * @remarks
 * It accepts two types D for body data type and R for response type
 *
 * @param url - Url of resource
 * @param method - POST | PATCH | PUT
 * @param data - Body data type of type D
 * @returns Response of type R
 *
 */
export const updateResource = async <D extends JsonData, R extends JsonData | JsonData[] | boolean | string>(
  url: string,
  method: 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  data: D,
  auth: boolean = true,
  asUser?: boolean,
  params?: Record<string, string | undefined>
): Promise<R> => {
  const qp = new URLSearchParams(sanitizeParams(params));

  // Create a stable key for SWR
  qp.sort();
  const qs = qp.toString();

  const response = await fetch(`${API_BASE_URL}${url}${qs.length > 0 ? `?${qs}` : ''}`, {
    headers: getHeaders(auth, asUser),
    body: JSON.stringify(data),
    method: method,
  });

  return checkResponse(response);
};

export const postUrlEncoded = async <R extends JsonData | JsonData[]>(
  url: string,
  data: Record<string, string>
): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(data),
    method: 'POST',
  });

  return checkResponse(response);
};

export const createQueryParams = (params?: Record<string, string | string[] | undefined>) => {
  const qp = new URLSearchParams(sanitizeParams(params));
  qp.sort();
  return qp.toString();
};

const sanitizeParams = (params?: Record<string, string | string[] | undefined>): Record<string, string> => {
  let sParams: Record<string, string> = {};

  if (params) {
    Object.keys(params)
      .filter((k) => params[k])
      .forEach((k) => (sParams[k] = params[k] as string));
  }

  return sParams;
};

export const getResource = async <
  R extends JsonData | JsonData[] | string[] | string | boolean | null | undefined
>(
  url: string,
  auth: boolean = false,
  fromApi: boolean = true,
  params?: Record<string, string | undefined>,
  asUser?: boolean
): Promise<R> => {
  const qp = new URLSearchParams(sanitizeParams(params));

  // Create a stable key for SWR
  qp.sort();
  const qs = qp.toString();

  const response = await fetch(`${fromApi ? API_BASE_URL : BASE_URL}${url}${qs.length > 0 ? `?${qs}` : ''}`, {
    headers: getHeaders(auth, asUser),
    redirect: 'follow',
  });

  return checkResponse(response, url);
};

const checkResponse = async <
  R extends JsonData | JsonData[] | string[] | string | boolean | null | undefined
>(
  response: Response,
  url?: string
): Promise<R> => {
  if (response.status >= 200 && response.status < 400) return response.json();
  if (response.status === 401) {
    resolveTokens(undefined, undefined);
  }

  const msg = `${response.status}: ${url}. ShopToken: ${getTokenData('token')}. UserToken: ${getTokenData(
    'userToken'
  )}`;
  throw new Error(msg);
};

export const getHeaders = (
  auth?: boolean,
  asUser?: boolean,
  includeContentType: boolean = true
): HeadersInit => {
  const token = JSON.parse((asUser ? getStorage('userToken') : getStorage('token')) || '{}');

  const companyLogin = getStorage('companyLogin');

  let h = {};
  if (includeContentType) {
    h = { ...headers };
  }
  if (auth) {
    h = { ...h, Authorization: `Bearer ${token}` };
  }

  if (companyLogin) {
    h = { ...h, 'Company-Login': 'lebeg' };
  }

  return h;
};

export const fetcher = async <
  R extends JsonData | JsonData[] | number[] | string[] | string | boolean | number
>(
  url: string,
  auth: boolean = false,
  asUser?: boolean
): Promise<R> => {
  const response = await fetch(url, {
    headers: getHeaders(auth, asUser),
  });
  if (response.ok) return response.json();

  if (response.status === 401) {
    resolveTokens(undefined, undefined);
  }

  const msg = `${response.status}: ${url}. ShopToken: ${getTokenData('token')}. UserToken: ${getTokenData(
    'userToken'
  )}`;
  throw new Error(msg);
};

export const useDataResource = <
  R extends JsonData | JsonData[] | number[] | string[] | string | boolean | number
>(
  url: string,
  auth: boolean = false,
  params?: Record<string, string | undefined>,
  r?: boolean,
  asUser?: boolean,
  dontFetch: boolean = false
) => {
  // const qp = new URLSearchParams(sanitizeParams(params));
  // // Create a stable key for SWR
  // qp.sort();
  // const qs = qp.toString();

  if (dontFetch) {
    return {
      data: undefined,
      isLoading: false,
      error: undefined,
      mutate: () => {},
    };
  }

  const qp = queryString.stringify(params || {});

  const { data, error, mutate } = useSWR<R>(
    [`${r ? R_BASE_URL : API_BASE_URL}${url}${qp.length > 0 ? `?${qp}` : ''}`, auth, asUser],
    fetcher,
    { shouldRetryOnError: false }
  );

  return {
    data: data,
    isLoading: data === undefined && !error,
    error: error,
    mutate: mutate,
  };
};

export const postFormData = async <R>(url: string, data: FormData): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: getHeaders(true, false, false),
    body: data,
    method: 'POST',
    redirect: 'follow',
  });

  const r = await response.json();
  if (response.ok) {
    return r;
  } else {
    throw new Error(r.detail);
  }
};
