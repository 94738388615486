import { motion } from 'framer-motion';
import { rgba } from 'polished';
import {
  FlexColumn,
  FlexReverse,
  FlexReverseCentered,
  FlexRowCentered,
  FlexSpaceBetweenCentered,
  FlexVerticalCentered,
} from 'src/components/utils/Containers';
import { AccountCheckResult } from 'src/models/utils';
import styled from 'styled-components';

export const ScoreContainer = styled.div<{ result: AccountCheckResult }>`
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.colors.grayscale.body};
  width: 128px;
  height: 90px;
  margin-bottom: 30px;
  font-style: italic;
  background-color: ${(props) =>
    props.result === 'error'
      ? props.theme.colors.grayscale.inputBg
      : props.result === 'passed'
      ? props.theme.colors.primary.lightBlue3
      : props.theme.colors.alert.light3};
`;

export const Score = styled.div<{ result: AccountCheckResult }>`
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
  color: ${(props) =>
    props.result === 'error'
      ? props.theme.colors.primary.darkBlue1
      : props.result === 'passed'
      ? props.theme.colors.primary.accentBlue
      : props.theme.colors.alert.accentRed};
`;

export const AccountCheckContainer = styled(motion.div)<{ type: AccountCheckResult }>`
  padding: 0 40px;
  padding-top: ${(props) => (props.type === 'failed' ? '48px' : '32px')};
  padding-bottom: ${(props) => (props.type === 'failed' ? '64px' : '16px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.line};
  margin-bottom: 16px;
  background-color: ${(props) => (props.type === 'failed' ? props.theme.colors.alert.light3 : 'white')};
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const RateContainer = styled(FlexSpaceBetweenCentered)<{ type: AccountCheckResult }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  background-color: ${(props) =>
    props.type === 'passed'
      ? rgba(props.theme.colors.primary.lightBlue3, 0.5)
      : props.type === 'error'
      ? props.theme.colors.grayscale.line
      : rgba(props.theme.colors.alert.light2, 0.5)};
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 100%;
  text-align: right;
  color: ${(props) => props.theme.colors.grayscale.gray1};
  font-size: 10px;
  font-style: italic;
  padding: 0 16px;
  font-weight: 400;
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayscale.titleDark};
  font-weight: 600;
`;

export const AccountCheckDescription = styled(FlexVerticalCentered)<{ type: AccountCheckResult }>`
  font-size: 12px;
  color: ${(props) =>
    props.type === 'failed'
      ? props.theme.colors.alert.dark
      : props.type === 'passed'
      ? props.theme.colors.primary.blue
      : props.theme.colors.grayscale.body};
  font-style: italic;
  margin-top: 24px;
  margin-bottom: 16px;
  border: 1px solid
    ${(props) =>
      props.type === 'failed'
        ? props.theme.colors.alert.dark
        : props.type === 'passed'
        ? props.theme.colors.primary.blue
        : props.theme.colors.grayscale.titleDark};

  padding: 16px 24px;
  border-radius: 8px;
`;

export const AccountCheckSteps = styled(FlexColumn)`
  border: 1px solid ${(props) => props.theme.colors.grayscale.inputBg};
  border-radius: 12px;
  background-color: white;
  padding: 32px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayscale.body};

  > ul {
    padding-inline-start: 24px;

    > li:not(:last-child) {
      margin-bottom: 8px;
      padding-right: 24px;
    }
  }
`;

export const MoreInfoContainer = styled(FlexColumn)<{ status: 'error' | 'pass' | 'fail' }>`
  padding: 0 48px;
  padding-top: 56px;
  padding-bottom: 88px;
  background-color: ${(props) => (props.status === 'pass' ? 'white' : props.theme.colors.grayscale.inputBg)};
  width: 880px;
`;

export const InfoText = styled.div`
  font-size: 10px;
  font-style: italic;
  color: ${(props) => props.theme.colors.grayscale.gray1};
`;

export const GraphImage = styled.img`
  margin: 16px 0;
  height: 164px;
  width: 400px;
  object-fit: cover;
`;

export const CTAContainer = styled(FlexVerticalCentered)`
  border-radius: 8px;
  background-color: ${(props) => rgba(props.theme.colors.notifications.light, 0.5)};
  padding: 20px 16px;
  font-size: 11px;
  font-style: italic;
  color: ${(props) => props.theme.colors.notifications.border};
  margin-top: 16px;
  width: 100%;
  font-weight: 500;
`;
export const PopupInfoContainter = styled(FlexVerticalCentered)`
  border-radius: 8px;
  background-color: ${(props) => rgba(props.theme.colors.notifications.light, 0.5)};
  padding: 20px 16px;
  font-size: 11px;
  font-style: italic;
  color: ${(props) => props.theme.colors.notifications.border};
  margin-top: 16px;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
  font-weight: 500;
`;

export const TestScore = styled.div`
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.notifications.default};
  padding: 2px 8px;
  font-size: 9px;
  color: ${(props) => props.theme.colors.notifications.default};
  font-style: normal;
  font-weight: 500;
`;

export const PassedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px 32px;
  width: 100%;
  margin-top: 16px;
`;

export const FailedGrid = styled(FlexColumn)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px 32px;
  width: 100%;
`;

export const TestText = styled.div<{ error?: boolean }>`
  font-weight: 700;
  color: ${(props) => (props.error ? props.theme.colors.alert.dark : props.theme.colors.grayscale.titleDark)};
  font-size: 16px;
`;

export const YellowTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.notifications.default};
`;
