import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { globalContext } from 'src/context';
import { CollapseIcon } from 'src/icons/CollapseIcon';
import { AnalyticsService } from 'src/services/Analytics';
import { RouteList } from './components/RouteList';
import { AuthType, ConnectionType, Status } from 'src/models/connection';
import { RefreshConnectionContainer } from './components/RefreshConnectionContainer';
import { ImportStatusContainer } from './components/ImportStatus';
import { ConnectionContainer } from './components/ConnectionContainer';
import { SectionIcon } from './components/SectionIcon';
import {
  SIDEBAR_GROUPS,
  SidebarGroup,
  IRoute,
  IRouteGroup,
  getAllowedRoutes,
  getDefaultRedirectRoute,
} from 'src/routes';
import { ConnectionErrorContainer } from './components/ConnectionErrorContainer';
import { LimitedAccessErrorContainer } from './components/LimitedAccessErrorContainer';
import { mutateAuth, mutateImportStatus } from 'src/context/reducer';
import { capitalizeSidebarGroup } from 'src/utils';

import * as S from './style';

export const Sidebar: React.FC = () => {
  const { state, dispatch } = useContext(globalContext);

  const router = useRouter();

  const [expanded, setExpanded] = useState(false);

  const [group, tab] = router.asPath.replaceAll('/', ' ').trim().split(' ');
  const [activeGroup, setActiveGroup] = useState<SidebarGroup>(group as SidebarGroup);
  const [activeTab, setActiveTab] = useState(group + tab);
  const [activeDropdowns, setActiveDropdowns] = useState<string[]>([]);

  const activeSidebarElement = SIDEBAR_GROUPS.filter((g) => g.key === activeGroup)[0];

  const allAllowedRoutes = getAllowedRoutes(state);

  const onIconClick = (type: SidebarGroup) => {
    if (type === activeGroup) {
      setExpanded((prev) => !prev);
      return;
    }

    setActiveGroup(type);
    setActiveDropdowns([]);

    !expanded && setExpanded((prev) => !prev);
  };

  const onRouteClick = (r: IRoute) => {
    if (!r.routes) {
      setActiveTab(activeGroup + r.to);
      AnalyticsService.track(`Route changed to ${activeGroup}/${r.to}`, { route: `${activeGroup}/${r.to}` });

      router.push(`/${activeGroup}/${r.to}`);
    } else {
      if (activeDropdowns.includes(r.to)) {
        const fad = activeDropdowns.filter((a) => a !== r.to);
        setActiveDropdowns(fad);
      } else {
        setActiveDropdowns(Array.from(new Set([...activeDropdowns, r.to])));
      }
    }
  };

  const connectionGetErrorMessage = (source: SidebarGroup): string | undefined => {
    if (
      source === 'competitors' &&
      state.shop &&
      state.shop.maxCompetitorsNum < state.competitors.filter((c) => !c.appUser).length
    ) {
      return `Your plan allows a maximum of ${state.shop?.maxCompetitorsNum} competitors. Thus remainder of competitors are currently blocked.
        Edit your competitors or change a plan.`;
    }

    if (source === 'google' && state.status.google.error) {
      return `Your ${capitalizeSidebarGroup(source)} connection has an error.` + state.status.google.error;
    }

    return undefined;
  };

  const shouldRefresh = (source: SidebarGroup): boolean => {
    if (source === 'ecommerce' && state.shop?.platform === 'noecommerce') return false;
    if (source === 'ecommerce') {
      return !state.adsAccountAuth[source].connected;
    }

    if (source === 'facebook' || source === 'google' || source === 'tiktok') {
      return state.adsAccountAuth[source].needsRefresh;
    }

    return false;
  };

  const isImporting = (source: SidebarGroup): boolean => {
    if (source === 'robot' || source === 'costs' || source === 'settings') return false;
    if (source === 'ecommerce' && state.shop?.platform === 'noecommerce') return false;

    let status = state.status[source] as Status;

    return !status.finished && status.connected === true && status.completed < status.total;
  };

  const isAnyAccountImported = (source: SidebarGroup): boolean => {
    if (source !== 'facebook' && source !== 'google' && source !== 'tiktok') return !isImporting(source);

    let accounts = state.status[source]?.accounts;
    if (!accounts) return !isImporting(source);

    for (let account of accounts) if (account.finished) return true;

    return false;
  };

  const getImportProgress = (source: SidebarGroup): number => {
    if (source === 'robot' || source === 'costs' || source === 'settings') return 0;

    let status = state.status[source] as Status;
    return status.completed / status.total;
  };

  const isAuthorized = (group: SidebarGroup): boolean => {
    if (group === 'facebook' || group === 'google' || group === 'tiktok') {
      return state.adsAccountAuth[group].connected;
    }
    if (group === 'ecommerce' && state.shop?.platform === 'noecommerce') return false;

    return true;
  };

  const isDisconnected = (source: SidebarGroup): boolean => {
    if (source === 'ecommerce' && state.shop?.platform === 'noecommerce') return true;
    if (source === 'robot' || source === 'costs' || source === 'settings') return false;

    let status = state.status[source] as Status;
    return !status.connected;
  };

  const getTrianglePosition = (group: IRouteGroup): string => {
    let groups = Object.values(SIDEBAR_GROUPS)
      .filter((g) => allAllowedRoutes.includes(g.key) && g.section === group.section)
      .map((g) => g.key);
    if (group.section === 'upper') {
      let idx = groups.indexOf(group.key);
      return `${187 + 56 * idx}px`;
    } else {
      let idx = groups.slice().reverse().indexOf(group.key);
      return `calc(100vh - ${46 + 56 * idx}px)`;
    }
  };

  const isGroupDisabled = (source: SidebarGroup): boolean => {
    if (source === 'competitors') {
      return false;
    }

    return isDisconnected(activeGroup) || !isAnyAccountImported(activeGroup);
  };

  const refreshProgress = () => {
    for (let group of ['ecommerce', 'facebook', 'google', 'tiktok', 'business-navigator']) {
      if (isImporting(group as SidebarGroup)) {
        mutateImportStatus(group as ConnectionType, dispatch);
      }
    }
  };

  const refreshAuth = () => {
    for (let group of ['facebook', 'google', 'tiktok', 'ecommerce']) {
      mutateAuth(group as AuthType, dispatch);
    }
  };

  const hompageClick = () => {
    const redirectRoute = getDefaultRedirectRoute(state);
    router.push(redirectRoute);
  };

  useEffect(() => {
    if (expanded !== state.sidebarExpanded) {
      dispatch({ type: 'toggle' });
    }
  }, [expanded]);

  useEffect(() => {
    if (state.scope.length === 0) return;
    const timeout = setTimeout(refreshAuth, 10000);

    return () => clearTimeout(timeout);
  }, [state.refreshAuth, state.scope]);

  useEffect(() => {
    if (state.scope.length === 0) return;
    const timeout = setTimeout(refreshProgress, 10000);

    return () => clearTimeout(timeout);
  }, [state.status, state.refreshImportProgress, state.scope]);

  useEffect(() => {
    if (state.scope.length === 0) return;

    if (['facebook', 'google', 'tiktok'].includes(activeGroup)) {
      mutateImportStatus(activeGroup as ConnectionType, dispatch);
    }
  }, [activeGroup, state.scope]);

  useEffect(() => {
    const [group, tab] = router.asPath.replaceAll('/', ' ').trim().split(' ');
    setActiveGroup(group as SidebarGroup);
    setActiveTab(group + tab);
  }, [router.pathname]);

  const renderSidebarIcon = (g: IRouteGroup) => {
    return (
      <SectionIcon
        active={activeGroup === g.key}
        onClick={() => onIconClick(g.key)}
        disconnected={isDisconnected(g.key)}
        alert={shouldRefresh(g.key) || !!connectionGetErrorMessage(g.key)}
        newIcon={g.newUntil && new Date() < g.newUntil}
        tasksCount={g.key === 'business-navigator' ? state.nextStepsCounter.counter : 0}
      >
        <g.Icon />
      </SectionIcon>
    );
  };

  return (
    <S.Wrapper>
      <S.SectionsContainer>
        <S.LogoContainer onClick={hompageClick}>
          <S.LebesgueLogo src={'/logo.png'} />
        </S.LogoContainer>

        <CollapseIcon
          style={{ cursor: 'pointer' }}
          expanded={expanded}
          onClick={() => setExpanded((prev) => !prev)}
        />

        <S.SectionIconsWrapper>
          <div>
            {SIDEBAR_GROUPS.filter((g) => allAllowedRoutes.includes(g.key) && g.section === 'upper').map(
              (g) => renderSidebarIcon(g)
            )}
          </div>
          <div>
            {SIDEBAR_GROUPS.filter((g) => allAllowedRoutes.includes(g.key) && g.section === 'lower').map(
              (g) => renderSidebarIcon(g)
            )}
          </div>
        </S.SectionIconsWrapper>
      </S.SectionsContainer>

      <AnimatePresence>
        {expanded && (
          <motion.div
            variants={{
              expanded: { width: 200, opacity: 1 },
              collapsed: { width: 0, opacity: 0 },
            }}
            animate={'expanded'}
            transition={{ duration: 0.5 }}
            initial={'collapsed'}
            exit={'collapsed'}
          >
            <S.NavigationContainer
              variants={{
                expanded: { opacity: 1, y: 0, transition: { opacity: { delay: 0.6 } } },
                collapsed: { opacity: 0, y: '-100px', transition: { y: { stiffness: 15000 } } },
              }}
              animate={'expanded'}
              initial={'collapsed'}
              exit={'collapsed'}
              trianglePos={getTrianglePosition(activeSidebarElement)}
              expanded={expanded}
            >
              <S.Title>{activeSidebarElement.title}</S.Title>

              {state.shop?.limitedAccess && <LimitedAccessErrorContainer />}

              {shouldRefresh(activeGroup) ? (
                <RefreshConnectionContainer activeGroup={activeGroup} />
              ) : (
                !!connectionGetErrorMessage(activeGroup) && (
                  <ConnectionErrorContainer
                    activeGroup={activeGroup}
                    message={connectionGetErrorMessage(activeGroup) as string}
                  />
                )
              )}

              {isImporting(activeGroup) && (
                <ImportStatusContainer
                  activeGroup={activeGroup}
                  expanded={expanded}
                  progress={getImportProgress(activeGroup)}
                />
              )}

              {isDisconnected(activeGroup) && (
                <ConnectionContainer
                  activeGroup={activeGroup}
                  expanded={expanded}
                  authorized={isAuthorized(activeGroup)}
                />
              )}

              <RouteList
                activeDropdowns={activeDropdowns}
                onRouteClick={onRouteClick}
                activeGroup={activeGroup}
                activeTab={activeTab}
                groupDisabled={isGroupDisabled(activeGroup)}
              />

              {/* <S.SwitchShopContainer>
                <Button
                  type="primary"
                  size="medium"
                  Icon={InternalLinkIcon}
                  style={{ marginLeft: -16 }}
                  onClick={() => router.push('/select')}
                >
                  Switch shop
                </Button>

                <Link href={`${API_BASE_URL}logout`} passHref>
                  <Button
                    type="secondary"
                    size="medium"
                    style={{ marginLeft: -16 }}
                    onClick={() => {
                      removeStorage('token');
                      removeStorage('userToken');
                      removeStorage('companyLogin');
                    }}
                  >
                    Log Out
                  </Button>
                </Link>
              </S.SwitchShopContainer> */}
            </S.NavigationContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};
