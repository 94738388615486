import styled, { css } from 'styled-components';
import { SpinnerIos } from '@styled-icons/fluentui-system-filled/SpinnerIos';
import { rotate } from 'styles/animations';

export const flexRowCentered = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexColumnCentered = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled(SpinnerIos)<{
  width: number;
  height: number;
  light?: boolean;
}>`
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  color: ${(props) => (props.light ? 'white' : props.theme.colors.primary.darkBlue1)};

  animation: ${rotate} 2s linear infinite;
`;
