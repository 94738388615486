import { useRouter } from 'next/router';
import { useContext } from 'react';

import { updateResource } from 'src/api';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import {
  FlexColumn,
  FlexColumnCentered,
  FlexRowCentered,
  FlexVerticalCentered,
  FullPageCenteredContainer,
  Video,
} from 'src/components/utils/Containers';
import { CenteredModalWrapper, ModalTitle } from 'src/components/utils/Modals';
import { SectionLoader } from 'src/components/utils/SectionLoader';
import { RegularDescription } from 'src/components/utils/Typography';
import { globalContext } from 'src/context';
import { capitalize } from 'src/helpers';
import { CheckmarkIcon } from 'src/icons/CheckmarkIcon';
import { Shop, ShopUpdateJson } from 'src/models/shop';
import {
  SIDEBAR_GROUPS,
  ALL_ROUTES,
  getAllowedRoutes,
  getDefaultRedirectRoute,
  getRedirectRouteKey,
} from 'src/routes';
import { TUTORIALS_CONFIG } from 'src/tutorials';
import { ThemeContext } from 'styled-components';
import { CTAContainer } from '../AccountQuality/style';
import { APPS_CONFIG } from 'src/appConfig';
import { useDataLoader } from 'src/hooks/loadData';
import { AnalyticsService } from 'src/services/Analytics';
import Custom404 from 'pages/404';

export const Root: React.FC = ({ children }) => {
  const { state, dispatch } = useContext(globalContext);
  const theme = useContext(ThemeContext);

  const router = useRouter();
  const loadingProgress = useDataLoader();

  if (loadingProgress < 1) {
    return (
      <FullPageCenteredContainer>
        <SectionLoader />
      </FullPageCenteredContainer>
    );
  }

  const { redirect } = router.query;

  if (redirect !== undefined && redirect !== '') {
    let redirectRoute = redirect as string;

    if (!getAllowedRoutes(state).includes(getRedirectRouteKey(redirectRoute))) {
      redirectRoute = getDefaultRedirectRoute(state);
      AnalyticsService.track(`Route changed to ${redirectRoute}`);
    }

    router.push(redirectRoute);
    return null;
  } else if (router.asPath.replaceAll('/', '') === '') {
    return null;
  }

  const introModalConfig = APPS_CONFIG[state.app ?? 'lebesgue'];
  const allowedCompetitors = state.competitors
    .filter((c) => !c.appUser)
    .slice(0, state.shop?.maxCompetitorsNum)
    .map((c) => c.id.toString());

  const onIntroModalClose = async () => {
    await updateResource<ShopUpdateJson, Shop>('shop/me', 'PATCH', { introModalShown: true }, true);
    dispatch({ type: 'show-intro-modal', payload: false });
  };

  const bookACall = () => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://calendly.com/toniperko';
    a.click();
  };

  const allAllowedRoutes = getAllowedRoutes(state);

  if (!state.plan || !state.modules || !state.scope) return null;

  const [group, tab] = router.asPath.replaceAll('/', ' ').trim().split(' ');
  let sidebarGroup = SIDEBAR_GROUPS.find((g) => g.key === group);

  if (sidebarGroup === undefined) {
    console.error('Unexistant sidebar group', group);
    return <Custom404 />;
  }

  let route = sidebarGroup.routes.find((r) =>
    r.routes ? r.routes.find((r) => r.to === tab)?.to === tab : r.to === tab
  );

  if (route?.routes) {
    route = route.routes.find((r) => r.to === tab);
  }

  if (route?.includedInPlans?.includes(state.plan) === false) return null;

  if (route && !allAllowedRoutes.includes(route.key)) {
    console.error('Unexistant tab', tab);
    return <Custom404 />;
  }

  if (
    group === 'competitors' &&
    (state.plan !== 'ultimate' || (!route && !allowedCompetitors.includes(tab)))
  ) {
    return null;
  }

  return (
    <>
      {children}
      {state.showLockedModal && (
        <Modal closeButton onClose={() => dispatch({ type: 'show-locked-modal', payload: null })}>
          <CenteredModalWrapper>
            <FlexRowCentered>
              <FlexColumnCentered style={{ marginRight: 64 }}>
                <ModalTitle style={{ width: 280 }}>
                  <b>
                    Upgrade your plan to{' '}
                    {state.showLockedModal.route.includedInPlans?.map((p) => capitalize(p)).join(' or ')}
                  </b>{' '}
                  to gain access to <br />
                  <span style={{ color: theme.colors.primary.blue }}>
                    {' '}
                    <b>
                      {TUTORIALS_CONFIG[state.showLockedModal.group][state.showLockedModal.route.to].title}
                    </b>
                  </span>
                </ModalTitle>

                <RegularDescription style={{ margin: '32px 0', textAlign: 'center', width: 350 }}>
                  {TUTORIALS_CONFIG[state.showLockedModal.group][state.showLockedModal.route.to].text}
                </RegularDescription>

                <CTAContainer style={{ marginBottom: 32 }}>
                  <FlexColumn>
                    <b style={{ fontStyle: 'normal' }}>
                      Upgrade your plan to{' '}
                      <i>
                        {state.showLockedModal.route.includedInPlans?.map((p) => capitalize(p)).join(' or ')}
                      </i>{' '}
                      for even more features:
                    </b>

                    <FlexVerticalCentered style={{ marginLeft: -4 }}>
                      {TUTORIALS_CONFIG[state.showLockedModal.group][
                        state.showLockedModal.route.to
                      ].features.map((f, i) => (
                        <FlexVerticalCentered key={i} style={{ marginRight: 8 }}>
                          <CheckmarkIcon style={{ marginRight: 2, height: 24, width: 24 }} /> {f}
                        </FlexVerticalCentered>
                      ))}
                    </FlexVerticalCentered>
                  </FlexColumn>
                </CTAContainer>

                <Button
                  type="primary"
                  size="big"
                  onClick={() => {
                    dispatch({ type: 'show-locked-modal', payload: null });
                    router.push('/settings/pricing');
                  }}
                >
                  Upgrade Now
                </Button>
              </FlexColumnCentered>

              <Video
                frameBorder="0"
                allowFullScreen
                src={TUTORIALS_CONFIG[state.showLockedModal.group][state.showLockedModal.route.to].video}
                height={512}
                width={640}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </FlexRowCentered>
          </CenteredModalWrapper>
        </Modal>
      )}

      {state.showIntroModal && (
        <Modal closeButton onClose={onIntroModalClose}>
          <CenteredModalWrapper>
            <FlexRowCentered>
              <FlexColumnCentered style={{ marginRight: 64 }}>
                <ModalTitle style={{ width: 256 }}>
                  Welcome to <b>{introModalConfig.title}</b>
                </ModalTitle>

                <RegularDescription style={{ margin: '32px 0', textAlign: 'center', width: 350 }}>
                  Get the most out of our app - maximize your benefits by watching the brief intro video or
                  booking a personalized call for a tailored tour.
                </RegularDescription>

                <Button type="notification" size="medium" onClick={bookACall}>
                  Book a demo call
                </Button>
              </FlexColumnCentered>

              <Video
                frameBorder="0"
                allowFullScreen
                src={introModalConfig.tutorialUrl}
                height={512}
                width={640}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </FlexRowCentered>
          </CenteredModalWrapper>
        </Modal>
      )}
    </>
  );
};
