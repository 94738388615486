import React, { useContext } from 'react';

import * as S from './style';
import { globalContext } from 'src/context';
import Head from 'next/head';
import { APPS_CONFIG } from 'src/appConfig';

export const SplashLayout: React.FC = ({ children }) => {
  const { state } = useContext(globalContext);
  return (
    <>
      <S.Wrapper>
        <S.SplashContainer>{children}</S.SplashContainer>
      </S.Wrapper>
    </>
  );
};
