import React, { useContext } from 'react';
import { AlertIcon } from 'src/icons/AlertIcon';
import { SidebarGroup, isSourceConnectAllowed } from 'src/routes';

import * as S from './style';
import { globalContext } from 'src/context';

interface Props {
  activeGroup: SidebarGroup;
  message: string;
}

export const ConnectionErrorContainer: React.FC<Props> = ({ activeGroup, message }) => {
  const { state } = useContext(globalContext);

  return (
    <S.RefreshConnectionContainer>
      <AlertIcon style={{ height: 24, marginBottom: 8 }} />
      {message}
      {!isSourceConnectAllowed(state, activeGroup) && (
        <div>Request from your admin to handle this issue as you don't have the required permission.</div>
      )}
    </S.RefreshConnectionContainer>
  );
};
