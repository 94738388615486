import React, { useState, useEffect } from 'react';
import { YellowDot } from 'src/components/utils/Containers';
import { AlertIcon } from 'src/icons/AlertIcon';
import { LockIcon } from 'src/icons/LockIcon';

import * as S from './style';
import { NextStepsCounterIcon } from 'src/icons/NextStepsCounterIcon';

interface Props {
  active: boolean;
  onClick(): void;
  disconnected: boolean;
  locked?: boolean;
  alert?: boolean;
  tasksCount?: number;
  newIcon?: boolean;
}

export const SectionIcon: React.FC<Props> = ({
  active,
  onClick,
  disconnected,
  locked,
  children,
  alert,
  newIcon,
  tasksCount = 0,
}) => {
  const [dc, setDc] = useState(disconnected);
  useEffect(() => {
    setDc(disconnected);
  }, [disconnected]);

  return (
    <S.SectionIconContainer
      active={active}
      onClick={onClick}
      whileTap={{ scale: 1.1 }}
      disconnected={dc}
      locked={locked}
    >
      {locked && (
        <S.IconContainer>
          <LockIcon />
        </S.IconContainer>
      )}
      {alert && (
        <S.IconContainer>
          <AlertIcon style={{ width: 10, height: 10 }} />
        </S.IconContainer>
      )}
      {tasksCount > 0 ? (
        <S.NewIconContainer style={{ top: -12, right: -8 }}>
          <div style={{ position: 'relative', width: 17, height: 19 }}>
            {/* <NextStepsCounterIcon style={{ position: 'absolute', width: '100%', height: '100%' }} /> */}
            <img
              src="https://nikolas-bucket.s3.amazonaws.com/shop/16038/source_id/mBvqgAgHU9eTudKdyYfXDN4Y7esiUpXe/Vector (1).png"
              height={19}
              width={17}
            />
            <S.NextStepsCounterText large={tasksCount >= 10}>
              {tasksCount < 10 ? tasksCount : '9+'}
            </S.NextStepsCounterText>
          </div>
        </S.NewIconContainer>
      ) : newIcon ? (
        <S.NewIconContainer>
          <YellowDot />
        </S.NewIconContainer>
      ) : null}
      {children}
    </S.SectionIconContainer>
  );
};
