import { init, setUserId, track } from '@amplitude/analytics-browser';
import { getStorage } from 'src/utils';

export class AnalyticsService {
  public static async init(): Promise<boolean> {
    if (process.env.NEXT_PUBLIC_ENV === 'prod' && !getStorage('companyLogin')) {
      await init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY).promise;

      return true;
    }

    return false;
  }

  public static track(eventName: string, properties?: Record<string, string>, tryCount: number = 0): void {
    if (process.env.NEXT_PUBLIC_ENV === 'prod' && !getStorage('companyLogin')) {
      try {
        track(eventName, properties);
      } catch {
        if (tryCount < 5) {
          setTimeout(() => AnalyticsService.track(eventName, properties, tryCount + 1), 1000);
        }
      }
    }
  }

  public static setUserId(shopName: string, tryCount: number = 0): void {
    if (process.env.NEXT_PUBLIC_ENV === 'prod' && !getStorage('companyLogin')) {
      try {
        setUserId(shopName);
      } catch {
        if (tryCount < 5) {
          setTimeout(() => AnalyticsService.setUserId(shopName, tryCount + 1), 1000);
        }
      }
    }
  }
}
