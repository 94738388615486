import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from 'styled-components';

const variants = {
  top: { rotate: 0 },
  down: { rotate: 180 },
};

export const DropdownIcon: React.FC<{
  style?: React.CSSProperties;
  collapsed: boolean;
  onClick?: () => void;
}> = ({ style, collapsed, onClick }) => {
  return (
    <motion.svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      variants={variants}
      animate={collapsed ? 'top' : 'down'}
      // transition={{ duration: 0.5 }}
      onClick={onClick}
    >
      <path d="M11 6L6 1L1 6" strokeLinecap="round" stroke={style?.color} strokeWidth={2} />
    </motion.svg>
  );
};
