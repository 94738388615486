const SEMI_MODULE_ROUTES = {
  lebesgue: [
    'ecommerce',
    'business',
    'product-intelligence',
    'stock-inventory',
    'ltv-analysis',
    'ltv-breakdowns',
    'ltv-product',
    'ltv-channel',
    'ltv-discount',
    'ltv-country',
    'ltv-state',

    'business-navigator',
    'next-steps',
    'creative-tab',

    'facebook',
    'fb-analytics',
    'fb-market',
    'fb-benchmarks',
    'fb-forecast',
    'fb-audit',

    'google',
    'ga-analytics',
    'ga-market',
    'ga-audit',

    'tiktok',
    'tt-analytics',
    'tt-audit',

    'robot',
    'ad-creator',
    'copywriter-product',
    'copywriter-adaptive',
    'copywriter-performance-based',

    'competitors',
    'competitors-landscape',
    'competitors-edit',

    'costs',
    'custom-costs',
    'product-costs',

    'settings',
    'settings-shop',
    'settings-integrations',
    'settings-pricing',
    'settings-billing-portal',
    'settings-user-administration',
  ],
  fb_audit: [
    'ecommerce',
    'business',

    'facebook',
    'fb-audit',

    'costs',
    'custom-costs',
    'product-costs',

    'settings',
    'settings-shop',
    'settings-integrations',
    'settings-billing-portal',
    'settings-user-administration',
  ],
  ga_audit: [
    'ecommerce',
    'business',

    'google',
    'ga-audit',

    'costs',
    'custom-costs',
    'product-costs',

    'settings',
    'settings-shop',
    'settings-integrations',
    'settings-billing-portal',
    'settings-user-administration',
  ],
  tt_audit: [
    'ecommerce',
    'business',

    'tiktok',
    'tt-audit',

    'costs',
    'custom-costs',
    'product-costs',

    'settings',
    'settings-shop',
    'settings-integrations',
    'settings-billing-portal',
    'settings-user-administration',
  ],
};

export const MODULE_ROUTES = { ...SEMI_MODULE_ROUTES, dev_lebesgue: SEMI_MODULE_ROUTES.lebesgue };

export const APPS_ONBOARDING_FLOW = {
  lebesgue: ['email', 'facebook', 'google', 'start'],
  dev_lebesgue: ['email', 'facebook', 'google', 'start'],
  fb_audit: ['email', 'facebook', 'start'],
  ga_audit: ['email', 'google', 'start'],
  tt_audit: ['email', 'tiktok', 'start'],
};

export const APPS_CONFIG = {
  lebesgue: {
    title: 'Lebesgue: Marketing & LTV',
    tutorialUrl: 'https://www.youtube.com/embed/6SBxY7f7jk8',
  },
  dev_lebesgue: {
    title: 'Lebesgue DEV: Marketing & LTV',
    tutorialUrl: 'https://www.youtube.com/embed/6SBxY7f7jk8',
  },
  fb_audit: {
    title: 'Lebesgue: Facebook audit',
    tutorialUrl: 'https://youtube.com/embed/v7mAfNDuWfY',
  },
  ga_audit: {
    title: 'Lebesgue: Google audit',
    tutorialUrl: 'https://youtube.com/embed/o3DuwFxI9sg',
  },
  tt_audit: {
    title: 'Lebesgue: TikTok audit',
    tutorialUrl: 'https://youtube.com/embed/NKKfYkZFIug',
  },
};

export type ScopeType =
  | 'admin'
  | 'full'
  | 'business'
  | 'product_intel'
  | 'ltv'
  | 'creative_strategist'
  | 'facebook'
  | 'google'
  | 'tiktok'
  | 'generate_copy'
  | 'publish_ad'
  | 'competitor'
  | 'competitors_edit'
  | 'competitors_emails'
  | 'custom_costs'
  | 'edit_custom_costs'
  | 'product_costs'
  | 'edit_product_costs'
  | 'settings_shop'
  | 'settings_integrations'
  | 'settings_billing';

export const SCOPE_ROUTES: Record<ScopeType, string[]> = {
  admin: [],
  full: [],
  business: ['ecommerce', 'business'],
  product_intel: ['ecommerce', 'product-intelligence', 'stock-inventory'],
  ltv: [
    'ecommerce',
    'ltv-analysis',
    'ltv-breakdowns',
    'ltv-product',
    'ltv-channel',
    'ltv-discount',
    'ltv-country',
    'ltv-state',
  ],
  creative_strategist: ['business-navigator', 'creative-tab'],
  facebook: ['facebook', 'fb-analytics', 'fb-market', 'fb-benchmarks', 'fb-forecast', 'fb-audit'],
  google: ['google', 'ga-analytics', 'ga-market', 'ga-audit'],
  tiktok: ['tiktok', 'tt-analytics', 'tt-audit'],
  generate_copy: [
    'robot',
    'ad-creator',
    'copywriter-product',
    'copywriter-adaptive',
    'copywriter-performance-based',
  ],
  publish_ad: [
    'robot',
    'ad-creator',
    'copywriter-product',
    'copywriter-adaptive',
    'copywriter-performance-based',
  ],
  competitor: ['competitors', 'competitor', 'competitors-landscape'],
  competitors_edit: ['competitors', 'competitor', 'competitors-edit'],
  competitors_emails: ['competitors', 'competitor', 'competitors-landscape'],
  custom_costs: ['costs', 'custom-costs'],
  edit_custom_costs: ['costs', 'custom-costs'],
  product_costs: ['costs', 'product-costs'],
  edit_product_costs: ['costs', 'product-costs'],
  settings_shop: ['settings', 'settings-shop'],
  settings_integrations: ['settings', 'settings-integrations'],
  settings_billing: [],
};

export const ROUTES_PRIORITY_ORDER = [
  'business',
  'creative-tab',
  'product-intelligence',
  'fb-analytics',
  'ga-analytics',
  'tt-analytics',
  'ltv-analysis',
  'competitors-landscape',
  'ad-creator',
  'settings-integrations',
  'custom-costs',
  'product-costs',
  'settings-shop',
  'settings-pricing',
];
