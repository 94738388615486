import styled from 'styled-components';

export const CenteredModalWrapper = styled.div`
  padding: 64px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grayscale.titleDark};
  text-align: center;
`;
